import React, { useState, useEffect } from "react";
import Common from "../../Utility/Common";
import DefaultModel from "../../Model/Admin/AdvertisementModel";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import FormHeader from "./FormHeader";
import AdminHeader from './AdminHeader'
import AdminNav from "./AdminNav";
import environment from '../../environment'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from '../Auth/Loader';
import { useHistory } from "react-router-dom";

toast.configure()
function Advertisement(props) {
    const history = useHistory();
    Common.ValidAdminLogin();
    const formData = new FormData();
    // const id = props.match.params.id;
    const id = props.location.search.split("=")[1]
    const [model, setModel] = useState(DefaultModel);
    const [imgurl, setImgurl] = useState('');
    const [loader, showLoader, hideLoader] = useLoader();
    const [border, setBorder] = useState("");
    const [showimage, setShowImage] = useState(false);
    const services = new service();

    useEffect(() => {
        if (id) {
            GetById(id);
        }
    }, []);
    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };

    const UploadImage = async (e) => {
        if (validImage(e)) {
            setImgurl()
            setShowImage(true)
            formData.append('file', e.target.files[0]);
            formData.append('imageType', '1');
            var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "advertisement", formData);
            var url = environment.imageUrl + data.result;
            setImgurl(url);
            setShowImage(false)
            let value = { image: url };
            await setModel({ ...model, ...value });
            setBorder({ ...border, [e.target.id]: "" })
        }
    }
    const validImage = (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg", "jfif", "bmp"];
        if (!allowedFileTypes.includes(fileExtension)) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("File does not support Upload jpg,png,jpeg,bmp files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
            return false;
        }
        if (file.size > 512000) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("Please upload a file smaller than 500KB", { theme: "colored", toastId: 'error5', autoClose: 3000 });
            return false;
        }
        return true;
    }

    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            let value = { userId: Common.AdminUserId() };
            setModel({ ...model, ...value });
            showLoader();
            if (model.id === null) {
                response = await services.Create(ServiceAPI.AdminAdvertisementCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.AdminAdvertisementUpdateApi, model);
            }
            if (response && response.message === "Success") {
                if (model.id === null) {
                    toast.success('Advertisement Added Successfully', { theme: "colored", autoClose: 2000 })
                } else {
                    toast.success('Advertisement Updated Successfully', { theme: "colored", autoClose: 2000 })
                }
                setTimeout(() => {
                    history.push("/advertisementlist");
                    setModel(DefaultModel);
                }, 1000);

            } else {
                hideLoader();
            }
        }
    };

    const GetById = async (id) => {
        showLoader()
        setModel(DefaultModel);
        var res = await services.GetById(ServiceAPI.AdminAdvertisementGetApi, id);
        hideLoader()
        const banner = await res.result;
        setModel(banner);
        setImgurl(banner.image);
    };

    const valid = () => {
        let Error = [];
        let name = "";
        let title = "";
        let image = "";
        let url = "";
        let description = ""
        function isValidWebUrl(url) {
            let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
            return regEx.test(url);
        }
        function isValidName(url) {
            let regEx = /^[A-Za-z]/
            return regEx.test(url);
        }
        if (!isValidName(model.name)) {
            Error.push("Valid name required");
            name = "borderred";
        }

        if (!Boolean(model.image)) {
            Error.push("Valid Image required");
            image = "borderred";
        }
        if (!isValidWebUrl(model.url.trim())) {
            Error.push("Valid Url required");
            url = "borderred"
        }
        if (!Boolean(model.description)) {
            Error.push("Valid Description required");
            description = "borderred"
        }
        if (name || title || image || url || description) {
            setBorder({ name, title, image, url, description });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };
    const back = () => {
        history.push("/advertisementlist");
    }
    return (
        <>
            <AdminHeader heading="" subheading="Manage Advertisement" />
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin master-admin">
                    <AdminNav advertisement="active"></AdminNav>
                    <div className="row">
                        <div className="col-md-6 mx-auto detail-box sm form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="label">Name<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="name"
                                        className={border.name ? border.name : "xu-input"}
                                        placeholder="Name"
                                        value={model.name}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-12">
                                    <div className="row m-0">
                                        <div className="col-md-10 pl-0">
                                            <div className="label">Image<span className="required">*</span></div>
                                            <input
                                                type="file"
                                                id="image"
                                                className={border.image ? `${border.image} xpading` : "xu-input xpading"}
                                                placeholder="Image"
                                                accept="image/*"
                                                onChange={(e) => UploadImage(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                                                : showimage ? <span className="img mt-3">
                                                    {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                                                    <div className="admin-img-preview mt-3">
                                                        <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                    </div>
                                                </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                                            {/* <img src={imgurl ? imgurl : "./images/default-img.jpg"} className="ad-img img-fluid" /> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="label">URL<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="url"
                                        className={border.url ? border.url : "xu-input"}
                                        placeholder="URL"
                                        value={model.url}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">Description<span className="required">*</span>{`(${model.description ? model.description.length : 0}/300)`}</div>
                                    <textarea
                                        type="text"
                                        rows="3"
                                        maxLength={300}
                                        id="description"
                                        className={border.description ? border.description : "xu-textarea"}
                                        placeholder="Description"
                                        value={model.description}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></textarea>
                                </div>

                                <div className="col-md-12">
                                    <div className="label">Active</div>
                                    <label className="switch">
                                        <input
                                            className=""
                                            type="checkbox"
                                            id="active"
                                            checked={model.active}
                                            onChange={(e) => ChangeHandler(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-md-12">
                                    <button
                                        className="btn btn-outline s-btn pr"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>{" "}
                        </div>{" "}
                    </div>
                    <div className='back-icon' onClick={back}></div>
                </div>

            </form>
            {loader}
        </>
    );
};


export default Advertisement
