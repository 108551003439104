import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/College/CollegeNewsModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import CollegeNav from "./CollegeNav";
import environment from '../../../environment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";

toast.configure()

const CollegeNews = (props) => {
  Common.ValidLogin();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const history = useHistory()
  const newDate = new Date();
  const formData = new FormData();
  const id1 = props.id;
  const show = props.show === true ? true : ''
  const [showimage, setShowImage] = useState(false);
  const [model, setModel] = useState(DefaultModel);
  const [imgurl, setImgurl] = useState('');
  const services = new service();
  const [date, setDate] = useState(newDate);
  const [border, setBorder] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();

  useEffect(() => {
    if (id1 && props.show === true) {
      props.setShow2(false)
      GetById(id1);
    }
    else if (props.match || !props) {
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1, show]);
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const UpadteDate = async (e, field) => {
    let value = {};
    value[field] = e;
    if (field === 'date')
      setDate(e);
    await setModel({ ...model, ...value });
    setBorder({ ...border, 'date': "" })
  };

  const UploadImage = async (e) => {
    setImgurl()
    setShowImage(true)
    formData.append('file', e.target.files[0]);
    formData.append('imageType', '1');
    var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "news", formData);
    var url = environment.imageUrl + data.result;
    setImgurl(url);
    setShowImage(false)
    let value = { imageUrl: url };
    await setModel({ ...model, ...value });
    setBorder({ ...border, 'date': "" })
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {

      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CollegeNewsCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CollegeNewsUpdateApi, model);
      }
      if (response && response.message === "Success") {
        if (props.id) {
          props.GetCollegeNews(Common.LoginId());
          $(`#${props.modalid}`).click();
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success1' })
          setTimeout(() => {
            hideLoader();
            $(`#${props.modalid}`).click();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');


          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetCollegeNews(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success1' })
          setModel(DefaultModel);
          setImgurl('');
          setTimeout(() => {
            $(`#${props.modalid}`).click();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success1' })
          setTimeout(() => {
            history.push("/collegenewslist");
            setModel(DefaultModel);
          }, 1000);

        }
      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
    }
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CollegeNewsGetApi, id);
    hideLoader()
    const event = await res.result;
    setModel(event);
    setImgurl(event.imageUrl);
    setDate(new Date(event.date));
  };

  const valid = () => {
    let Error = [];
    let title = "";
    let imageUrl = "";
    let date = "";
    let description = "";
    if (!Boolean(model.title)) {
      Error.push("Valid Title required");
      title = "borderred";
    }
    if (model.title.length > 50) {
      Error.push("Valid Length required for Title");
      title = "borderred";
    }
    if (!Boolean(model.imageUrl)) {
      Error.push("Valid Image required");
      imageUrl = "borderred";
    }
    if (!Boolean(model.date)) {
      Error.push("Valid Date required");
      date = "borderred";
    }
    if (new Date(model.date) > new Date()) {
      Error.push("Date Can't In Future");
      date = "borderred";
    }
    if (!Boolean(model.description)) {
      Error.push("Valid Description required");
      description = "borderred";
    }

    if (title || imageUrl || date || description) {
      setBorder({ title, imageUrl, date, description });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/collegenewslist");
  }
  const DeleteNews = async (id) => {
    showLoader()
    const res = await services.Delete(ServiceAPI.CollegeNewsDeleteApi, id)
    hideLoader()
    if (res.message == "Success") {
      if (props.id) {
        props.GetCollegeNews("delete");
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');

        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage News" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <CollegeNav news="active"></CollegeNav>}
          <div className="row">
            <div className="mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-6">
                  <div className="label">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model.title}
                    maxLength={50}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Date<span className="required">*</span></div>
                  <DatePicker
                    id="date"
                    className={border.date ? border.date : "xu-input"}
                    selected={date}
                    onChange={(e) => UpadteDate(e, 'date')}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>

                <div className="col-md-12">
                  <div className="label">description<span className="required">*</span> <span>{`(${parseInt(model.description ? model.description.length : 0)}/500)`}</span></div>
                  <textarea
                    maxLength={500}
                    type="text"
                    rows="3"
                    id="description"
                    className={border.description ? border.description : "xu-textarea"}
                    placeholder="description"
                    value={model.description}
                    onChange={(e) => ChangeHandler(e)}
                  ></textarea>

                </div>
                <div className="col-md-12">
                  <div className="row m-0">
                    <div className="col-md-10 pl-0">
                      <div className="label xy-label-float">Choose Image<span className="required">*</span></div>
                      <input
                        type="file"
                        id="imageUrl"
                        className={border.imageUrl ? `${border.imageUrl} xpading` : "xu-input xpading"}
                        placeholder="Image"
                        accept="image/*"
                        onChange={(e) => UploadImage(e)}
                      ></input>
                    </div>
                    <div className="col-md-2 pr-0">
                      {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                        : showimage ? <span className="img ">
                          {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                          <div className="admin-img-preview">
                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          </div>
                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                      {/* <img src={imgurl ? imgurl : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" /> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteNews(model.id)}
                  >

                  </a>
                </div> : ""}
                <div className="col-md-12 spacing">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );

}
export default CollegeNews;