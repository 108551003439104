import React, { useState } from 'react'
import service from "../Services/AdminUtilityService";
import ServiceAPI from "../Utility/ServiceList";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from './Auth/Loader';
import { useHistory } from "react-router-dom";

toast.configure()

const DefaultModel = () => ({
    type: '',
    emailAddress: ''
});
function Invitation() {
    const history = useHistory();
    const services = new service();
    const [model, setModel] = useState(DefaultModel);
    const [loader, showLoader, hideLoader] = useLoader();
    const [border, setBorder] = useState("");


    const ChangeHandler = (e) => {
        let value = {};
        value[e.target.id] = e.target.value;
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            showLoader();
            response = await services.Create(ServiceAPI.InvitationCreateApi, model);
            hideLoader();
            if (response && response.message === "Success") {
                toast.success('Email Sent Successfully ', { theme: "colored", autoClose: 3000, toastId: 'success1' })
                setTimeout(() => {
                    history.back()
                }, 2000);
            }
        }
    };

    const valid = () => {
        function isValidWebUrl(s) {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(s);
        }
        let emailAddress = "";
        let type = "";

        if (!Boolean(model.type)) {
            toast.error("Select Valid Type", { theme: "colored", toastId: 'error2' });
            type = "borderred";
        }
        // if (!Boolean(model.emailAddress)) {
        //     toast.error("Email Address required", { theme: "colored" });
        //     emailAddress = "Enter Valid Email Address";
        // }
        if (!isValidWebUrl(model.emailAddress)) {
            toast.error("Valid Email Address required", { theme: "colored", toastId: 'error3' });
            emailAddress = "borderred";
        }
        if (emailAddress || type) {
            setBorder({ emailAddress, type });
            return false;
        }
        return true;
    };
    // var preloader = document.getElementById('preloader');

    // function myFunction() {
    //     preloader.style.display = 'block';
    // }

    return (
        <>
            <form onSubmit={handleSubmit} autoComplete="off">
                <div className="theme-container-admin min-h master-admin">
                    <div className="row">
                        <div className="col-md-4 mx-auto detail-box form-sec ">
                            <div className="row align-items-center m-0">
                                <div className="col-md-12">
                                    <div className="label">Type<span className="required">*</span></div>
                                    <select className={border.type ? border.type : "xu-input"} id="type" value={model.type} onChange={(e) => ChangeHandler(e)}>
                                        <option value="0">Select</option>
                                        {
                                            ["College", "Canditdate", "Company"].map((item) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">Email<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="emailAddress"
                                        className={border.emailAddress ? border.emailAddress : "xu-input"}
                                        placeholder="Email"
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div >
                            </div>
                            <div className=" pt-3 pb-3">
                                <button
                                    className="btn a-btn btn-outline s-btn mt-0 float-right"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default Invitation
