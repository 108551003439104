import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Student/StudentAchivementModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import StudentNav from "./StudentNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";

toast.configure()

const StudentAchievement = (props) => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  Common.ValidLogin();
  const formData = new FormData();
  const id1 = props.id;
  const show = props.show == true ? true : ''
  const year = new Date().getFullYear();
  const [showimage, setShowImage] = useState(false);
  const [model, setModel] = useState(DefaultModel);
  const [imgurl, setImgurl] = useState('');
  const [error, setError] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const [border, setBorder] = useState("xu-input");

  const services = new service();

  useEffect(() => {
    if (id1 && props.show === true) {
      GetById(id1);

    }
    else if (props.match || !props) {
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1, show]);
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const UploadImage = async (e) => {
    setImgurl()
    setShowImage(true)
    formData.append('file', e.target.files[0]);
    formData.append('imageType', '1');
    var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "achievement", formData);
    var url = environment.imageUrl + data.result;
    setImgurl(url);
    setShowImage(false)
    let value = { image: url };
    await setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.StudentAchievementCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.StudentAchievementUpdateApi, model);
      }
      if (response && response.message === "Success") {

        if (props.id) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentAchievement();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          $(`#${props.modalid}`).click();
          setTimeout(() => {
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentAchievement();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          $(`#${props.modalid}`).click();
          setImgurl("");
          setTimeout(() => {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            history.push("/studentachievementlist");
          }, 2000);
        }
      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
    }

    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.StudentAchievementGetApi, id);
    hideLoader()
    const banner = await res.result;
    setModel(banner);
    setImgurl(banner.image);
  };

  const valid = () => {
    let Error = [];
    let title = "";
    let image = "";
    let description = "";
    let year = ''
    if (!Boolean(model.title)) {
      Error.push("Valid Title required");
      title = "borderred";
    }
    if (model.title.length > 50) {
      Error.push("Valid Length required for Title");
      title = "borderred";
    }
    if (!Boolean(model.description)) {
      Error.push("Valid description required");
      description = "borderred";
    }
    if (!Boolean(model.year) || model.year === "0") {
      Error.push("Valid Year of Achievement required");
      year = "borderred";
    }

    if (title || image || description || year) {
      setBorder({ title, image, description, year });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored" });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/studentachievementlist");
  }
  const DeleteAchievement = async (id) => {
    showLoader()
    var res = await services.Delete(ServiceAPI.StudentAchievementDeleteApi, id);
    hideLoader()
    if (res.message == "Success") {
      if (props.id) {
        hideLoader();
        props.GetStudentAchievement();
        toast.success('Successfully deleted...', { theme: "colored", autoClose: 1000 })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');
        }, 2000)
      }

    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Achievement" />}
      <form onSubmit={Create} autoComplete="off" >
        <div className="theme-container-admin">
          {props.id || props.id2 ? "" : <StudentNav achievement="active"></StudentNav>}
          <div className="row">
            <div className="  mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-6">
                  <div className="label">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model.title}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>

                <div className="col-md-6">
                  <div className="label">Year Of Achievement<span className="required">*</span></div>
                  <select className={border.year ? border.year : "xu-input"} value={model.year} id="year" onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      Array.from(new Array(100), (v, i) =>
                        <option key={i} value={year - i}>{year - i}</option>
                      )}
                  </select>
                </div>
                <div className="col-md-10">
                  <div className="label">Image </div>
                  <input
                    type="file"
                    id="image"
                    className={border.image ? `${border.image} xpading` : "xu-input xpading"}
                    placeholder="Image"
                    accept="image/*"
                    onChange={(e) => UploadImage(e)}
                  ></input>
                </div>
                <div className="col-md-2">
                  {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                    : showimage ? <span className="img">

                      <div className="admin-img-preview">
                        <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      </div>
                    </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}


                </div>

                <div className="col-md-12">
                  <div className="label">description<span className="required">*</span>{`(${model.description ? model.description.length : 0}/300)`}</div>
                  <textarea
                    maxLength={300}
                    minLength={150}
                    type="text"
                    id="description"
                    className={border.description ? border.description : "xu-textarea"}
                    placeholder="description"
                    value={model.description}
                    onChange={(e) => ChangeHandler(e)}
                    rows="5"
                  ></textarea>
                </div>

                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteAchievement(model.id)}
                  >

                  </a>
                </div> : ""}
                <div className={props.id ? "col-md-12" : "col-md-12"}>
                  <button
                    className="btn btn-outline s-btn pr"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
};

export default StudentAchievement;
