import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/College/CollegeAboutUsModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import CollegeNav from "./CollegeNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import $ from "jquery";
import AdminHeader from '../../Common/AdminHeader'
import { useHistory } from "react-router-dom";

toast.configure()
const CollegeAboutUs = (props) => {
    const history = useHistory();
    const [model, setModel] = useState(DefaultModel);
    const [imgurl, setImgurl] = useState([]);
    const [showimage, setShowImage] = useState(false);
    const services = new service();
    Common.ValidLogin();
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const year = new Date().getFullYear();
    const id1 = Common.LoginId();
    const formData = new FormData();
    const [aboutUsId, setAboutUsId] = useState('');
    const [border, setBorder] = useState("");
    const [loader, showLoader, hideLoader] = useLoader();

    useEffect(() => {

        if (id1) {
            GetById(id1);
        }
        else if (props.match || !props) {
            const id = props.match.params.id;
            if (id) { GetById(id); }
        }
    }, []);

    const GetById = async (id) => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        setModel(DefaultModel);
        var res = await services.GetById(ServiceAPI.CollegeGetAboutUs, id);
        hideLoader()
        const data = await res.result;
        setModel(data);
        setImgurl(data.image)
        //  setImgurl(data.images.map((item) => item.imageUrl));
        setAboutUsId(data.id);
    };
    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };

    const UploadImage = async (e) => {
        setImgurl()
        setShowImage(true)
        formData.append('file', e.target.files[0]);
        formData.append('imageType', '1');
        var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "about", formData);
        var url = environment.imageUrl + data.result;

        setImgurl(url);
        setShowImage(false)
        let value = { image: url };
        await setModel({ ...model, ...value });
    }

    const Create = async (e) => {
        e.preventDefault();
        var response = null;

        if (valid()) {
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            showLoader();
            if (model.id === 0) {
                response = await services.Create(ServiceAPI.CollegeAboutUsCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.CollegeAboutUsUpdateApi, model);
            }
            if (response && response.message === "Success") {
                if (props.id) {
                    props.GetcollegeAboutus(Common.LoginId());
                    $(`#${props.modalid}`).click();
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success1' })
                    setTimeout(() => {
                        $(`#${props.modalid}`).click();
                        hideLoader();
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)

                }
                else if (props.id2) {
                    $(`#${props.modalid}`).click();
                    props.GetcollegeAboutus(Common.LoginId());
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success2' })
                }
                else {
                    setTimeout(

                        function () {
                            hideLoader();
                            history.push("/collegeaboutus");
                        }, 2000);
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success3' })

                }
            }
            else {
                hideLoader();
            }
        }
    };

    const valid = () => {
        let Error = [];
        let heading = "";
        let description = "";
        let website = "";
        let noOfStudents = "";
        let qualificationsOffered = '';
        let headquerters = "";
        let founded = "";

        if (!Boolean(model.heading)) {
            Error.push("Valid heading required");
            heading = "borderred";
        }
        if (model.heading.length > 500) {
            Error.push("Valid Length required for heading");
            heading = "borderred";
        }

        if (!Boolean(model.description)) {
            Error.push("Valid Description required");
            description = "borderred";
        }
        if (!Boolean(model.website)) {
            Error.push("Valid Website required");
            website = "borderred";
        }
        if (!Boolean(model.noOfStudents)) {
            Error.push("Valid no Of Students required");
            noOfStudents = "borderred";
        }
        if (!Boolean(model.qualificationsOffered)) {
            Error.push("Valid qualifications Offered required");
            qualificationsOffered = "borderred";
        }
        if (!Boolean(model.headquerters)) {
            Error.push("Valid headquerters required");
            headquerters = "borderred";
        }
        if (!Boolean(model.founded) || model.founded === "0") {
            Error.push("Valid founded required");
            founded = "borderred";
        }

        if (heading || description || website || noOfStudents || qualificationsOffered || headquerters || founded) {
            setBorder({ heading, description, website, noOfStudents, qualificationsOffered, headquerters, founded });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };

    return (
        <>
            {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage About Us" />}
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin min-h">
                    {props.id || props.id2 ? "" : <CollegeNav aboutus="active"></CollegeNav>}
                    <div className="row">
                        <div className="mx-auto detail-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="label">Heading<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="heading"
                                        maxLength={140}
                                        className={border.heading ? border.heading : "xu-input"}
                                        placeholder="Heading"
                                        value={model.heading}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-12">
                                    <div className="label">Description<span className="required">*</span>{`(${model.description ? model.description.length : 0}/500)`}</div>
                                    <textarea
                                        maxLength={500}
                                        type="text"
                                        rows="3"
                                        id="description"
                                        className={border.description ? border.description : "xu-textarea"}
                                        placeholder="Description"
                                        value={model.description}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></textarea>
                                </div>

                                <div className="col-md-6">
                                    <div className="label">Website<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="website"
                                        className={border.website ? border.website : "xu-input"}
                                        placeholder="Website"
                                        value={model.website}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Number Of Students<span className="required">*</span></div>
                                    <input
                                        type="number"
                                        id="noOfStudents"
                                        className={border.noOfStudents ? border.noOfStudents : "xu-input"}
                                        placeholder="NumberOfStudents"
                                        value={model.noOfStudents}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Qualifications Offered<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="qualificationsOffered"
                                        className={border.qualificationsOffered ? border.qualificationsOffered : "xu-input"}
                                        placeholder="Qualifications Offered"
                                        value={model.qualificationsOffered}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Headquarters<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="headquerters"
                                        className={border.headquerters ? border.headquerters : "xu-input"}
                                        placeholder="Headquarters"
                                        value={model.headquerters}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-6">
                                    <div className="row m-0">
                                        <div className="col-md-10 pl-0">
                                            <div className="label xy-label-float">Choose Image</div>
                                            <input
                                                type="file"
                                                id="image"
                                                className="xu-input xpading"
                                                placeholder="Image"
                                                accept="image/*"
                                                onChange={(e) => UploadImage(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                                                : showimage ? <span className="img ">
                                                    {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}

                                                    <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                                                </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                                            {/* <img src={imgurl ? imgurl : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Founded<span className="required">*</span></div>
                                    <select className={border.founded ? border.founded : "xu-input"} value={model.founded} id="founded" onChange={(e) => ChangeHandler(e)}>
                                        <option value="0">Select</option>
                                        {
                                            Array.from(new Array(100), (v, i) =>
                                                <option key={i} value={year - i}>{year - i}</option>
                                            )}
                                    </select>
                                </div>
                                <div className="col-md-12 d-flex">
                                    <div className='display-inline pl-0 unset'>
                                        {/* <input type="checkbox" className='mr-2' onChange={(e) => checkBox(e, category)} /> */}
                                        <label className="check-container">
                                            <input type="checkbox" id="inPersonTeaching" checked={model.inPersonTeaching} onChange={(e) => ChangeHandler(e)} />
                                            <span className="ccheckmark"></span>
                                        </label>
                                        <div className='pl-2 label text-dark mt-0'>
                                            In-person Teaching
                                        </div>

                                    </div>

                                    <div className='display-inline ml-2 pl-0 unset'>
                                        {/* <input type="checkbox" className='mr-2' onChange={(e) => checkBox(e, category)} /> */}
                                        <label className="check-container">
                                            <input type="checkbox" id="onlineTeaching" checked={model.onlineTeaching} onChange={(e) => ChangeHandler(e)} />
                                            <span className="ccheckmark"></span>
                                        </label>
                                        <div className='pl-2 label text-dark mt-0'>
                                            Online Teaching
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 spacing">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right auto"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default CollegeAboutUs
