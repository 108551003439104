import React, { useRef, useState, useEffect } from "react";
import RegistrationModel from "../../Model/Auth/RegistrationModel";
import ValidationService from "../../Services/ValidationService";
import service from "../../Services/RegistrationService";
import services from '../../Services/UtilityService'
import imgservice from "../../Services/UtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import PhoneInput from 'react-phone-input-2'
import environment from '../../environment';
import 'react-phone-input-2/lib/style.css'
import $ from 'jquery'
import Multiselect from 'multiselect-react-dropdown';
// import Multiselect from "../Auth/multiselect-dropdown";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { response } from "react";
import useLoader from './Loader';
import Avtar from './Avtar'


toast.configure()
const Signup = () => {
  const apiKey = "AIzaSyAVLTGiMQzxgYBYnviizjkO9g - HRLIEMH4";
  const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
  const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
  const searchInput = useRef('');
  const servicess = new services();
  const today = new Date().toISOString().split("T")[0];
  const [loader, showLoader, hideLoader] = useLoader();
  const [model, setModel] = useState(RegistrationModel())
  // const [encodedmodel, setEncodedModel] = useState('')
  const [count, setCount] = useState()
  const [error, setError] = useState(true)
  const [languagelist, setLanguageList] = useState([{ name: 'Option1', value: 'Option1', id: "1" }, { name: 'Option2', value: 'Option2', id: "2" }, { name: 'Other', value: 'Other', id: "3" }])
  const [errorMessage, setErrorMessage] = useState([])
  const [step1, setStep1] = useState("show")
  const [step2, setStep2] = useState("hide")
  const [step3, setStep3] = useState("hide")
  const [step4, setStep4] = useState("hide")
  const [active1, setActive1] = useState(false)
  const [step1Class, setStep1Class] = useState("active")
  const [step2Class, setStep2Class] = useState("")
  const [step3Class, setStep3Class] = useState("")
  const [step4Class, setStep4Class] = useState("")
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [CollegeClass, setCollegeClass] = useState('')
  const [CandidateClass, setCandidateClass] = useState('')
  const [CompanyClass, setCompanyClass] = useState('')
  const [border, setBorder] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [showimage, setShowImage] = useState(false);
  const [showavtar, setShowAvtar] = useState(true)

  useEffect(() => {
    GetLanguage();
    $("#mod").on("click", () => {
      $("#mod").removeClass("modal-backdrop")
    })
    // $(".input-box").addClass("hide")
  }, [])

  useEffect(() => {
    var value = {}
    if (model.UserType == "candidate") {
      value['UserType'] = "candidate"
      value['Website'] = "http://www.website.com"
      value['UserName'] = "string"
    }
    else {
      value['dob'] = today
      value['language'] = [""]
    }
    setModel({
      id: null,
      FirstName: "",
      LastName: "",
      Email: "",
      UserName: "",
      Password: "",
      ConfirmPassword: "",
      UserType: model.UserType,
      Website: "",
      MobileNo: "",
      Logo: "",
      Address: "",
      City: "",
      State: "",
      Country: "",
      PinCode: "",
      errormsg: "",
      dob: "",
      gender: "",
      language: '',
      agreeToTerms: false,
      ...value
    })
  }, [CollegeClass, CandidateClass, CompanyClass,])

  const loadAsyncScript = async (src) => {
    return new Promise(resolve => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src
      })
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    })
  }
  const GetLanguage = async () => {
    const resp = await servicess.GetAll(ServiceAPI.GetLanguageApi);
    const industryData = await resp.result.map((item) => {
      return {
        name: item.languageName,
        value: item.languageName,
        id: item.languageId,
        languageId: item.languageId
      }

    });
    setLanguageList(industryData);
  }
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const togglePassword1 = (e) => {
    e.preventDefault();
    setPasswordShown1(!passwordShown1);
  };
  const initMapScript = async () => {
    // if script already loaded
    if (window.google) {
      return await Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return await loadAsyncScript(src);
  }
  const extractAddress = (place) => {
    const address = {
      City: "",
      State: "",
      Country: "",
      PinCode: "",
      Address: "",
      plain() {
        const City = City ? City + ", " : "";
        const Pincode = Pincode ? Pincode + ", " : "";
        const State = State ? State + ", " : "";
        const Address = Address ? Address + ", " : "";
        const Country = Country ? Country + ", " : "";
        return City + Pincode + State + Country + Address;
      }
    }

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach(component => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("locality")) {
        address.City = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.State = value;
      }

      if (types.includes("postal_code")) {
        address.PinCode = value;
      }

      if (types.includes("country")) {
        address.Country = value;
      }
      if (place) {
        address.Address = place.formatted_address.split(address.City)[0];
      }
    });
    return address;
  }
  if (model.Password !== '') {
    var newModel = model
    localStorage.setItem("signup", JSON.stringify(newModel))
  }

  // do something on address change
  const onChangeAddress = async (autocomplete) => {
    const place = await autocomplete.getPlace();
    var values = extractAddress(place)

    setModel({ ...JSON.parse(localStorage.getItem("signup")), ...values })
  }
  // init autocomplete
  const initAutocomplete = () => {
    // alert("a")
    if (!searchInput.current) {
      return;
    }
    else {
      const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
      autocomplete.setFields(["address_component", "geometry", "formatted_address"]);
      autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }
  }

  const ChangeHandler = async (e, field) => {
    let value = {};
    // $(".input-box").addClass("hide")
    if (e.target.nodeName === 'DIV') {
      if (e.target.id === 'dvCollege' || e.target.id === 'dvCandidate' || e.target.id === 'dvCompany') {
        if (e.target.id == "dvCandidate") {
          value['UserType'] = "candidate"
          value['Website'] = "http://www.website.com"
          value['UserName'] = "string"
        }
        else {
          value['dob'] = today
          value['language'] = []
          value['UserType'] = e.target.innerHTML;
        }
      }
    }
    else if (field === "language") {
      // let value = { languages: [...model.languages, { studentId: studentId ? studentId : null, attributeId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, attribute: null, id: null, userId: Common.UserId(), active: true }] };
      // await setModel({ ...model, ...value });
    }
    else {
      value[e.target.name] = e.target.value;
      // if (e.target.name == "Address") {
      //   initMapScript().then(() => initAutocomplete())
      // }

    }
    await setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.name]: "" })

  }
  const RemoveValue = async (e, field) => {
    if (field === "language") {
      let value = { language: e }
      await setModel({ ...model, ...value });

    }
  }
  const handleOnChange = async (value) => {
    await setModel({ ...model, MobileNo: value });
  }
  const ChangeStep = (e, step) => {
    e.preventDefault();
    if (validation(step)) {
      setStep1('hide'); setStep1Class(''); setStep2('hide'); setStep2Class(''); setStep3('hide'); setStep3Class(''); setStep4('hide'); setStep4Class('')
      if (step === 'step1') {
        setStep2("show")
        setStep2Class("active")
        // setTimeout(() => inputFirstName.current.focus(), 0);
      }
      else if (step === 'step2') {
        setStep3("show")
        setStep3Class("active")
        // setTimeout(() => inputEmail.current.focus(), 0);
      }
      else if (step === 'step3') {
        setStep4('show')
        setStep4Class("active")
        //  setTimeout(() => inputAddress, 0);
      }
      else if (step === 'step4')
        setStep4('show')
    }
  }
  const Step = (e, step) => {
    e.preventDefault();

    if (step === 'step1') {
      setStep1Class(''); setStep2('hide'); setStep2Class(''); setStep3('hide'); setStep3Class(''); setStep4('hide'); setStep4Class('')
      if (validation(step)) {
        setStep1('show')
        setStep1Class('active')
      }
    }
    else if (step === 'step2') {
      if (validation("step1")) {
        Changeprogress("step1")
        setStep2('show')
        setStep2Class('active')
      }
    }
    else if (step === 'step3') {
      if (validation("step1")) {
        if (validation("step2")) {
          Changeprogress("step2")
          setStep3('show')
          setStep3Class('active')
        }
      }
    }
    else if (step === 'step4')
      if (validation("step1")) {
        if (validation("step2")) {
          if (validation("step3")) {
            Changeprogress("step3")
            setStep4('show')
            setStep4Class('active')
          }
        }
      }
  }
  const Changeprogress = (step) => {
    setStep1('hide'); setStep1Class(''); setStep2('hide'); setStep2Class(''); setStep3('hide'); setStep3Class(''); setStep4('hide'); setStep4Class('')
    if (step === 'step1') {
      setStep2("show")
      setStep2Class("active")
      // setTimeout(() => inputFirstName.current.focus(), 0);
    }
    else if (step === 'step2') {
      setStep3("show")
      setStep3Class("active")
      // setTimeout(() => inputEmail.current.focus(), 0);
    }
    else if (step === 'step3') {
      setStep4('show')
      setStep4Class("active")
      // setTimeout(() => inputAddress, 0);
    }
    else if (step === 'step4')
      setStep4('show')

  }
  const SelectType = async (e) => {
    setShowImage(false)
    setStep1('show')
    setCollegeClass('');
    setCandidateClass('');
    setCompanyClass('');
    setCount();
    if (e.target.id === 'dvCollege') {
      setCollegeClass('active');
    }
    else if (e.target.id === 'dvCandidate') {
      setCandidateClass('active');
    }
    else if (e.target.id === 'dvCompany') {
      setCompanyClass('active');
    }
    ChangeHandler(e);
  }

  const UploadImage = async (e, img) => {
    const services = new imgservice();
    // const formData = new FormData();
    // formData.append('file', img.target.files[0].type);
    // formData.append('imageType', '1');

    setShowAvtar(false)
    setTimeout(() => {
      setShowAvtar(true)
      $("#exampleMediaAdd").removeClass("modal2")
    }, 10);
    setShowImage(true)
    var s = {
      bitcode: e.split(',')[0],
      userId: "",
      role: CollegeClass ? "college" : CandidateClass ? "candidate" : CompanyClass ? "company" : '',
      typeDir: "logo",
      extension: `${img.target.files[0].type.split("/")[1]}`
    }
    var data = await services.Create(ServiceAPI.EncodedImageUplaodApi, s);

    var url = environment.imageUrl + data.result;
    let id = url.split('/').splice(-3, 1).toString()
    setShowImage(false)
    let value = { 'Logo': url, "id": '' };
    await setModel({ ...model, ...value });
    setBorder({ ...border, "Logo": "" })



  }
  const validation = (step) => {
    setError(false)
    function isValidWebUrl(url) {
      let regEx = /^http?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
      return regEx.test(url);
    }
    setErrorMessage([])
    var error = false;
    var arrError = [];
    let Logo = '';
    let terms = true;
    let FirstName = ''; let LastName = ''; let UserName = ''; let dob = ''; let language = '';
    let Email = ''; let Password = ''; let ConfirmPassword = ''; let Website = ''; let MobileNo = '';
    let Address = ''; let City = ''; let PinCode = ''; let State = ''; let Country = '';
    if (step === 'step1') {
      if (!ValidationService.validationNotEmpty(model.UserType)) {
        // toast.error("Please select account for", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        arrError.push("Please select account for");
        error = true;
      }
    }
    else if (step === 'step2') {
      if (!ValidationService.validationNotEmpty(model.FirstName)) {
        // toast.error("Please Enter firstname", { theme: "colored", autoClose: 3000, toastId: 'error2' });
        arrError.push("Please Enter firstname");
        error = true;
        FirstName = "borderred"
      }
      if (!ValidationService.validationNotEmpty(model.Logo)) {
        // toast.error("Please Enter valid logo ", { theme: "colored", autoClose: 3000, toastId: 'error' });
        if (CandidateClass == "active") {

        }
        else {
          arrError.push("Please Enter valid logo");
          error = true;
          Logo = "borderred1"
        }

      }
      if (!ValidationService.validationNotEmpty(model.LastName)) {
        // toast.error("Please Enter lastname", { theme: "colored", autoClose: 3000, toastId: 'error3' });
        arrError.push("Please Enter lastname");
        error = true;
        LastName = "borderred"
      }
      if (!ValidationService.validationNotEmpty(model.UserName)) {
        if (CollegeClass == "active") {
          // toast.error("Please Enter College Name", { theme: "colored", autoClose: 3000, toastId: 'error14' });
          arrError.push("Please Enter College Name");
          error = true;
          UserName = "borderred"
        }
        else if (CompanyClass == "active") {
          // toast.error("Please Enter Company Name", { theme: "colored", autoClose: 3000, toastId: 'error14' });
          arrError.push("Please Enter Company Name");
          error = true;
          UserName = "borderred"
        }
      }
      if (model.UserName.length < 3 && model.UserName.length != 0) {
        if (CollegeClass == "active") {
          toast.error("Must have minimum 3 characters in College Name", { theme: "colored", autoClose: 3000, toastId: 'error14' });
          arrError.push("Must have minimum 3 characters in College Name");
          error = true;
          UserName = "borderred"
        }
        else if (CompanyClass == "active") {
          // toast.error("Must have minimum 3 characters in Company Name", { theme: "colored", autoClose: 3000, toastId: 'error14' });
          arrError.push("Must have minimum 3 characters in Company Name");
          error = true;
          UserName = "borderred"
        }
      }


      if (!ValidationService.validationNotEmpty(model.dob)) {
        // toast.error("Please Enter Date of Birth", { theme: "colored", autoClose: 3000, toastId: 'error4' });
        arrError.push("Please enter Date of Birth");
        error = true;
        dob = "borderred"
      }
      if (model.language.length == 0) {
        // toast.error("Please Enter Gender", { theme: "colored", autoClose: 3000, toastId: 'error5' });
        arrError.push("Please select language");
        error = true;
        language = "borderred2"
      }
    }
    else if (step === 'step3') {
      if (!ValidationService.validationNotEmpty(model.Email)) {
        // toast.error("Please enter Email", { theme: "colored", autoClose: 3000, toastId: 'error6' });
        arrError.push("Please enter Email");
        error = true;
        Email = "borderred"
      }
      if (!ValidationService.validationEmail(model.Email)) {
        // toast.error("Please enter Valid Email ", { theme: "colored", autoClose: 3000, toastId: 'error6' });
        arrError.push("Please enter Valid Email");
        error = true;
        Email = "borderred"
      }
      // if (ValidationService.validationEmail(model.Email)) {
      //   var arr1 = ["gmail", "hotmail", "yahoo", "aol", "hotmail", "msn", "yahoo", "live", "rediffmail", "outlook", "example", "mail", "redmail"]
      //   var reqEmailDomain = (model.Email.split("@")[1]);
      //   var reqEmailDomains = reqEmailDomain.split(".")[0];
      //   var newarr = arr1.filter((data) => data == reqEmailDomains)
      //   if (newarr.length >= 1) {
      //     arrError.push("Please enter your company's email");
      //     error = true;
      //     Email = "borderred"
      //   }

      // }
      if (!ValidationService.validationNotEmpty(model.Password)) {
        // toast.error("Please enter Password", { theme: "colored", autoClose: 3000, toastId: 'error7' });
        arrError.push("Please enter Password");
        error = true;
        Password = "borderred"
      }
      if (!ValidationService.validationPassword(model.Password)) {
        // toast.error("Invalid password!, Password must have minimum 8 characters includes numbers,lowercase and uppercase letters.", { theme: "colored", autoClose: 3000, toastId: 'error7' });
        arrError.push("Invalid password!, Password must have minimum 8 characters includes numbers, lowercase and uppercase letters.");
        error = true;
        Password = "borderred"
      }
      if (!ValidationService.validationNotEmpty(model.ConfirmPassword)) {
        // toast.error("Please enter ConfirmPassword", { theme: "colored", autoClose: 3000, toastId: 'error07' });
        arrError.push("Please enter ConfirmPassword");
        error = true;
        ConfirmPassword = "borderred"
      }

      if (model.Password !== model.ConfirmPassword) {
        // toast.error("Passwords dont match", { theme: "colored", autoClose: 3000, toastId: 'error07' });
        arrError.push('Passwords dont match');
        error = true;
        ConfirmPassword = "borderred"
      }
      if (!ValidationService.validationNotEmpty(model.Website)) {
        if (CollegeClass == "active" || CompanyClass == "active") {
          // toast.error("Please enter Website", { theme: "colored", autoClose: 3000, toastId: 'error8' });
          arrError.push("Please enter  Website");
          error = true;
          Website = "borderred"
        }
      }
      // if (!isValidWebUrl(model.Website)) {
      //   if (CollegeClass == "active" || CompanyClass == "active") {
      //     arrError.push("Please enter Valid Website");
      //     Website = "borderred"
      //   }
      // }
      if (!ValidationService.validationNotEmpty(model.MobileNo)) {
        // toast.error("Please enter MobileNo", { theme: "colored", autoClose: 3000, toastId: 'error9' });
        arrError.push("Please enter  MobileNo");
        error = true;
        MobileNo = "borderred"
      }
    }
    else if (step === 'step4') {
      // if (!ValidationService.validationNotEmpty(model.Address)) {
      //   // toast.error("Please enter Address", { theme: "colored", autoClose: 3000, toastId: 'error10' });
      //   arrError.push("Please enter Address");
      //   error = true;
      //   Address = "borderred"
      // }
      if (!ValidationService.validationNotEmpty(model.City)) {
        // toast.error("Please enter City", { theme: "colored", autoClose: 3000, toastId: 'error11' });
        arrError.push("Please enter City");
        error = true;
        City = "borderred"
      }
      if (!ValidationService.validationNotEmpty(model.PinCode)) {
        // toast.error("Please enter Postal Code", { theme: "colored", autoClose: 3000, toastId: 'error12' });
        arrError.push("Please enter Postal Code");
        error = true;
        PinCode = "borderred"
      }
      if (!ValidationService.validationNotEmpty(model.State)) {
        // toast.error("Please enter State", { theme: "colored", autoClose: 3000, toastId: 'error13' });
        arrError.push("Please enter State");
        error = true;
        State = "borderred"
      }
      if (!model.agreeToTerms) {
        arrError.push("Please accept terms and conditions");
        error = true;
      }
      if (!ValidationService.validationNotEmpty(model.Country)) {
        // toast.error("Please enter Country", { theme: "colored", autoClose: 3000, toastId: 'error14' });
        arrError.push("Please enter Country");
        error = true;
        Country = "borderred"
      }
    }
    if (Logo || FirstName || LastName || UserName || dob || language ||
      Email || Password || ConfirmPassword || Website || MobileNo ||
      Address || City || PinCode || State || Country) {
      setBorder({
        FirstName, LastName, UserName, dob, language, Logo,
        Email, Password, ConfirmPassword, Website, MobileNo,
        Address, City, PinCode, State, Country
      });
    }
    if (arrError.length === 0) {
      arrError = [];
      return true;
    } else if (arrError.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (arrError.length <= 2) {
      arrError.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };

  const Signup = async (e) => {
    e.preventDefault();
    //localStorage.clear()
    if (validation('step4')) {
      const services = new service();
      showLoader();
      var response = await services.registration(model);

      if (response === "success") {
        toast.success('Signup successfully, Please wait for account activate by Admin', { theme: "colored", autoClose: 3000, toastId: 'Success1' })
        setTimeout(
          function () {
            hideLoader();
            window.location = "/login";
          }, 3000);
      } else {
        hideLoader();
      }
    }
  }
  // useEffect(() => {
  //   initMapScript().then(() => initAutocomplete())
  // }, [searchInput]);
  const RemoveLanguage = (params) => {
    setModel({ ...model, language: params.length > 0 ? params : '' })
    setCount(params.length)
  }

  const selectLanguage = (params) => {
    // const s = [...params.map((item) => languagelist.filter((items) => item == items.name))
    //   .map((item) => {
    //     return { "languageId": item[0].id }
    //   })]
    setModel({ ...model, language: params.length > 0 ? params : '' })
    setCount(params.length)
  }
  var newcount = 0;
  const setFilter = () => {
    if (newcount == 0) {
      $(".ind-menu").removeClass("show")
      $(".ind-menu").addClass("hide")
      newcount = 0;
    }

  }
  const setDropdown1 = () => {
    newcount = 1
    if (active1 == false) {
      $(".ind-menu").addClass("show")
      $("body").addClass("drop-open")
      $("#dropdownMenu2").addClass("active")
      setActive1(true);
    }
    else {
      $(".ind-menu").removeClass("show")
      $("body").removeClass("drop-open")
      $("#dropdownMenu2").removeClass("active")
      setActive1(false)
    }
  }

  const closeModal = (type) => {
    setShowAvtar(false)
    setTimeout(() => {
      setShowAvtar(true)
      $("#exampleMediaAdd").removeClass("modal2")
    }, 10);

  }
  const customModal = () => {
    $("#exampleMediaAdd").addClass("modal2")
  }
  return (
    <div >

      <div className="row align-items-center justify-content-center center-row" >
        <div className="clgName">
          {CandidateClass == 'active' ? <h4 className="color">{CandidateClass == 'active' && "Candidate"}</h4> : ""}
          {CompanyClass == 'active' ? <h4 className="color">{CompanyClass == 'active' && "Company"}</h4> : ""}
          {CollegeClass == 'active' ? <h4 className="color">{CollegeClass == 'active' && "College"}</h4> : ""}
        </div>
        <ul className="progressbar">
          <li className={step1Class} onClick={(e) => Step(e, 'step1')}><a href="#" >Select Type</a></li>
          <li className={step2Class} onClick={(e) => Step(e, 'step2')}><a href="#" >Profile</a> </li>
          <li className={step3Class} onClick={(e) => Step(e, 'step3')}><a href="#" >Details</a></li>
          <li className={step4Class} onClick={(e) => Step(e, 'step4')}><a href="#" >Location</a></li>

        </ul>
      </div>

      <section className="section bg-sec login-h lt">
        <div className="container-fluid login-sec admin" onClick={() => setFilter()}>
          <div className="sign-box  ">
            {/* side img */}
            <div className="col-md-6">
              <img src={step1Class == "active" ? "./images/select.png" : step2Class == "active" ? "./images/profile.png" : step3Class == "active" ? "./images/details.png" : "./images/location-2.png"} className="sign-img" alt="" />
            </div>
            {/* <div className="col-md-6">
              <img src="./images/select.png" className="sign-img" alt="" />
            </div> */}
            <div className="col-md-6 bg-lines" >
              <div className="login-box type-box">
                <div id="step1" className={step1}>
                  <div className="sign-heading">create account for</div>
                  {/* select type */}
                  <div className="single-tab form-sec">
                    <div id="dvCollege" className={"tab-heading " + CollegeClass} onClick={(e) => SelectType(e)} >college</div>
                  </div>
                  <div className="single-tab form-sec">
                    <div id="dvCandidate" className={"tab-heading " + CandidateClass} onClick={(e) => SelectType(e)} >candidate</div>
                  </div>
                  <div className="single-tab form-sec">
                    <div id="dvCompany" className={"tab-heading " + CompanyClass} onClick={(e) => SelectType(e)}>company</div>
                  </div>
                  <button
                    type="submit"
                    className="btn sign-btn btn-outline big-btn"
                    onClick={(e) => ChangeStep(e, 'step1')}
                  >
                    Signup
                  </button>
                </div>
                {/* profile */}

                <div id="step2" className={'form-sec ' + step2} >
                  <div className="sign-heading">profile</div>
                  <div className="text-center position-relative" >

                    {/* <img src={model.Logo ? model.Logo : "./images/default-user-img.svg"} className={border.Logo ? `${border.Logo} profile-login` : "profile-login"} /> */}

                    {model.Logo ? <img src={model.Logo} className={border.Logo ? `${border.Logo} profile-login` : "profile-login"} />
                      : showimage ? <div className="profile-login loder"   >
                        {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}

                        <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                      </div> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-user-img.svg`} className={border.Logo ? `${border.Logo} profile-login` : "profile-login"} />}
                    <span className="cam-input" id="mod" onClick={() => customModal()} />
                    {/* <input type="file" name="Logo" className="cam-input"   /> */}
                    {/* onChange={(e) => UploadImage(e)} */}
                  </div>

                  <div className="row m-0">
                    <div className="col-md-6 pl-0">
                      <div className="label"> {CandidateClass === "active" ? `First Name` : `Administrator First Name`} <span className="required">*</span></div>
                      <input
                        autoComplete="none"
                        type="text"
                        className={border.FirstName ? border.FirstName : "xu-input"}
                        name="FirstName"
                        placeholder="First Name"
                        onChange={(e) => ChangeHandler(e)}
                        value={model.FirstName}
                      ></input>
                      <br />
                    </div>
                    <div className="col-md-6 pr-0">
                      <div className="label">{CandidateClass == "active" ? `Last Name` : `Administrator Last Name`} <span className="required">*</span></div>
                      <input
                        autoComplete="none"
                        type="text"
                        className={border.LastName ? border.LastName : "xu-input"}
                        name="LastName"
                        placeholder="Last Name"
                        onChange={(e) => ChangeHandler(e)}
                        value={model.LastName}
                      ></input>
                      <br />
                    </div>
                    {(CandidateClass == "active") ?
                      <>
                        <div className="col-md-6 pl-0">
                          <div className="label"> {CandidateClass == "active" ? `Date of birth` : ``}<span className="required">*</span></div>
                          <input
                            autoComplete="none"
                            max={today}
                            type="date"
                            className={border.dob ? border.dob : "xu-input"}
                            name="dob"
                            placeholder="Date of birth"
                            onChange={(e) => ChangeHandler(e)}
                            value={model.dob}
                          ></input>
                          <br />
                        </div>
                        <div className="col-md-6 pr-0 language-select">
                          <div className="label">{CandidateClass == "active" ? `languages` : ``} <span className="required">*</span></div>
                          <div className="position-relative">
                            {count ?
                              <span className="count">
                                {count}
                              </span>
                              : ""}
                            <Multiselect
                              className="xu-input"
                              id="language"
                              onSelect={(e) => selectLanguage(e)}
                              selectedValues={model.studentLanguage}
                              options={languagelist}
                              showCheckbox={true}
                              closeOnSelect={false}
                              displayValue="name"
                              onRemove={(e) => RemoveLanguage(e)}

                            />
                          </div>
                          {/* <div className="hide" id="showlg">
                            <input
                              autoComplete="none"
                              type="text"
                              className={border.language ? `${border.language}` : "xu-input"}
                              name="langauge"
                              placeholder="eg..South Afrika"
                              onChange={(e) => ChangeHandler(e, "language")}

                            ></input>
                          </div> */}
                          {/* <div className="filter-title dropdown" id="lang">
                            <button className={border.language ? `${border.language} locs-btn xu-input` : `locs-btn xu-input`} type="button" id="dropdownMenu2" onClick={() => setDropdown1()} >
                              Select <span><img src="../../images/icon-chevron.svg" /></span>
                              {count ?
                                <span className="count">
                                  {count}
                                </span>
                                : ""}
                            </button>
                            <div class="dropdown-menu locs-menu ind-menu" style={{ "zIndex": "1" }} aria-labelledby="dropdownMenu2">
                              <Multiselect
                                className="xu-input"
                                id="language"
                                onSelectOptions={(e) => selectLanguage(e)}
                                type="language"
                                options={languagelist}
                              />

                            </div>
                          </div> */}
                          {/* <div className="label">{CandidateClass == "active" ? `language` : ``} <span className="required">*</span></div> */}

                          {/* <select className={border.gender ? border.gender : "xu-input"} name="gender" onChange={(e) => ChangeHandler(e)}>
                            <option value="0">Select</option>
                            <option key="male" > Male </option>
                            <option key="Female">Female </option>
                          </select> */}
                        </div>
                      </>
                      : ""}
                    {(CompanyClass === "active") ? <>
                      <div className="col-md-12 p-0">
                        <div className="label">Company Name <span className="required">*</span></div>
                        <input
                          autoComplete="none"
                          type="text"
                          className={border.UserName ? border.UserName : "xu-input"}
                          name="UserName"
                          value={model.UserName}
                          onChange={(e) => ChangeHandler(e)}
                        ></input>
                      </div>
                    </>
                      : CollegeClass ? <>
                        <div className="col-md-12 p-0">

                          <div className="label">College Name <span className="required">*</span></div>
                          <input
                            autoComplete="none"
                            type="text"
                            className={border.UserName ? border.UserName : "xu-input"}
                            name="UserName"
                            onChange={(e) => ChangeHandler(e)}
                            value={model.UserName}
                          ></input>
                        </div>
                      </> : ""}
                  </div>
                  <button
                    type="submit"
                    className="btn sign-btn btn-outline big-btn"
                    onClick={(e) => ChangeStep(e, 'step2')}
                  >
                    Continue
                  </button>
                </div>

                {/* details */}
                <div id="step3" className={'form-sec ' + step3}>
                  <div className="sign-heading">details</div>
                  <div>
                    <div className="label">Email<span className="required">*</span></div>
                    <input autoComplete="none" value={model.Email} type="text" className={border.Email ? border.Email : "xu-input"} name="Email" onChange={(e) => ChangeHandler(e)}></input>
                  </div>

                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="label">Password<span className="required">*</span></div>
                        <div className="password-input">
                          <input type={passwordShown ? "text" : "password"} value={model.Password} autoComplete="none" className={border.Password ? border.Password : "xu-input"} name="Password" onChange={(e) => ChangeHandler(e)}></input>
                          {passwordShown ?
                            <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword(e)} />
                            :
                            <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword(e)} />
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="label">confirm Password<span className="required">*</span></div>
                        <div className="password-input">
                          <input autoComplete="none" value={model.ConfirmPassword} type={passwordShown1 ? "text" : "password"} className={border.ConfirmPassword ? border.ConfirmPassword : "xu-input"} name="ConfirmPassword" onChange={(e) => ChangeHandler(e)}></input>
                          {passwordShown1 ?
                            <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword1(e)} />
                            :
                            <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword1(e)} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    CandidateClass == "active" ? '' : <div> <div className="label">Website<span className="required">*</span></div>
                      <input autoComplete="none" value={model.Website} type="text" className={border.Website ? border.Website : "xu-input"} placeholder="www.example.com" name="Website" onChange={(e) => ChangeHandler(e)}></input>
                    </div>
                  }

                  <div>
                    <div className="label ">Contact<span className="required">*</span></div>
                    <PhoneInput value={model.MobileNo}
                      className="react-tel-input" country={'us'} name="MobileNo" onChange={(e) => handleOnChange(e)} />
                    {/* <input type="text" className="xu-input-2" />
                    <input type="text" className="xu-input-3" name="MobileNo" onChange={this.ChangeHandler.bind(this)}></input> */}
                  </div>
                  <button
                    type="submit"
                    className="btn sign-btn btn-outline big-btn"
                    onClick={(e) => ChangeStep(e, 'step3')}
                  >
                    Continue
                  </button>

                </div>

                {/* location */}

                <div id="step4" className={'form-sec ' + step4}>
                  <div className="sign-heading">location</div>
                  {/* <div>
                     
                    <div className="label">Address<span className="required">*</span></div>
                    <input ref={searchInput} type="text" value={model.Address} className={border.Address ? border.Address : "xu-input"} name="Address" onChange={(e) => ChangeHandler(e)}></input>
                  </div> */}

                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="label">City<span className="required">*</span></div>
                        <input autoComplete="off" type="text" className={border.City ? border.City : "xu-input"} value={model.City} name="City" onChange={(e) => ChangeHandler(e)}></input>
                      </div>

                      <div className="col-md-6">
                        <div className="label"> Postal Code<span className="required">*</span></div>
                        <input autoComplete="none" type="text" className={border.PinCode ? border.PinCode : "xu-input"} name="PinCode" value={model.PinCode} onChange={(e) => ChangeHandler(e)}></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="label"> Province/ State<span className="required">*</span></div>
                        <input autoComplete="none" type="text" className={border.State ? border.State : "xu-input"} name="State" value={model.State} onChange={(e) => ChangeHandler(e)}></input>
                      </div>

                      <div className="col-md-6">
                        <div className="label">Country<span className="required">*</span></div>
                        <input autoComplete="none" type="text" className={border.Country ? border.Country : "xu-input"} name="Country" value={model.Country} onChange={(e) => ChangeHandler(e)}></input>
                      </div>
                      <div className="d-flex w-100">
                        <label className="container privacyText"><input autoComplete="none" type="checkbox" value={model.agreeToTerms} id="agreeToTerms" name="agreeToTerms" onChange={e => { setModel({ ...model, agreeToTerms: e.target.checked }); }} /><span className="checkmark checkmarkTop" /><div>{"Agree To Terms &"}</div></label><a href="#" className="privacyButton" onClick={(e) => { e.preventDefault(); setShowPrivacy(true) }} >{"Privacy Policy"}</a>
                      </div>
                    </div >
                  </div >

                  <button
                    type="submit"
                    className="btn sign-btn btn-outline big-btn"
                    onClick={(e) => Signup(e)}
                  >
                    Finish
                  </button>
                </div >

              </div >

            </div >
          </div >

        </div >

      </section >
      < div className="modal modal1 bg-modal"

        id={`exampleMediaAdd`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered " >
          <div className="modal-content">
            <div className="modal-header flex">
              {/* <div className="modal-header "> */}
              <div className="preview-title">PROFILE IMAGE </div>
              <button type="button" className="modalclose-btn" data-dismiss-modal="modal2" onClick={() => closeModal()}>&times;</button>
              {/* </div> */}
            </div>
            <div className="modal-body">
              {showavtar ? <Avtar UploadImage={UploadImage} /> : ""}
            </div>
          </div>
        </div>
      </div >
      {loader}
      {showPrivacy && <div className="privacyModal fp-container"
        id={"privacyPolicy"}
      >
        <div className="privacyModalBackground" onClick={() => { setShowPrivacy(false) }} />
        <div className="block w-75 rounded bg-light pt-2">
          <div className="modal-header p-1 pb-2">
            <h5 className="font-weight-bold mb-1">Privacy Policy</h5>
            <button type="button" className="m-0 close modalclose-btn pedit2" data-dismiss="modal" onClick={() => setShowPrivacy(false)}>&times;</button>
          </div>
          <div className="modal-body p-2">
            <div className="privacy-text terms-text ">

              <p>
                At Xylus (Pty) Ltd (2019/524679/07) <b>(“Xylus”)</b>{" "}
                we adhere to the highest standards of protecting your
                personal information when we process it by virtue of
                your use of our Services and Platform,{" "}
                <a href="www.xylus.com">www.xylus.com </a>and any
                related platforms (collectively, “<b>the Platform</b>
                ”), or by providing us with your personal information
                in any other way. As such, we have created this
                privacy policy for you to read and to understand how
                we safeguard your personal information and respect
                your privacy (“
                <b>Privacy Policy</b>”).{" "}
              </p>
              <p>
                Please note that Xylus is a private company duly
                registered and operating in accordance with the laws
                of the Republic of South Africa. Not all terms are
                necessarily defined in order or may be defined in our
                Terms and Conditions of Use (“<b>Terms</b>”).
              </p>
              Please ensure that you read all the provisions below,
              and our policies and guidelines which may apply from
              time to time, to understand all of your, and our, rights
              and duties.
              <ul>
                <li>
                  {" "}
                  <b> Important Information and Who We Are</b>
                </li>
                <ul>
                  <li>
                    {" "}
                    <u> Purpose of this Privacy Policy</u>
                  </li>
                  <ul>
                    <li>
                      This Privacy Policy aims to give you information
                      on how we collect and process your personal
                      information through any form of your engagement
                      with us. This Privacy Policy complies with, and
                      facilitates the obligations required from, the
                      South African Protection of Personal Information
                      Act, No. 4 of 2013 (“<b>POPI</b>”), as amended.{" "}
                    </li>
                    <li>
                      It is important that you read this Privacy
                      Policy together with any other privacy policy or
                      fair processing notice we may provide on
                      specific occasions when we are collecting or
                      processing personal information about you, so
                      that you are fully aware of how and why we are
                      using your personal information. This Privacy
                      Policy supplements the other notices and is not
                      intended to override them.
                    </li>
                  </ul>
                </ul>
              </ul>
              <p>
                Due to the nature of our operations we may process
                certain special categories of personal information. We
                process this information to provide our services and
                to ensure our platform users receive the best possible
                service.{" "}
                <b>
                  {" "}
                  By accepting this Privacy Policy, you, as a
                  parent/guardian expressly and informedly consent to
                  our processing of the personal information of your
                  minor child where we will always store and process
                  any minor personal information at a higher standard
                  than conventional personal information.
                </b>
              </p>
              <ul>
                <li>
                  {" "}
                  <u> Responsible Party and Operator</u>
                </li>
                <ul>
                  <li>
                    We are the “Responsible Party” and is responsible
                    for your personal information when we decide its
                    processing operations. In certain instances we may
                    operate as an “Operator” of personal information
                    on behalf of a third party.
                  </li>
                  <li>
                    <p>
                      We have appointed an information officer at
                      Xylus who is responsible for overseeing
                      questions in relation to this Privacy Policy. If
                      you have any questions about this Privacy
                      Policy, including any requests to exercise your
                      legal rights, please contact our information
                      officer using the details set out below.
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    {" "}
                    <u> Our Contact Details</u>
                  </li>
                  <ul>
                    <li>Our full details are:</li>
                    <ul>
                      <li>
                        {" "}
                        <b> Full name:</b> Xylus (Pty) Ltd
                      </li>
                      <li>
                        {" "}
                        <b> Information Officer:</b> Ciaran Ramdeo
                      </li>
                      <li>
                        {" "}
                        <b> Email address:</b>  admin@xylus.com
                      </li>
                      <li>
                        <b> Postal address:</b> PO Box 991, Umhlanga,
                        Durban, 4320
                      </li>
                      <li>
                        <p>
                          {" "}
                          <b> Telephone number:</b> (+27) 067 470 9654
                        </p>
                      </li>
                    </ul>
                    <li>
                      You have the right to make a complaint at any
                      time to the South African regulator’s office (
                      <u>
                        {" "}
                        Information Regulator’s Office of South Africa
                      </u>
                      ). We would, however, appreciate the chance to
                      deal with your concerns before you approach any
                      such regulator, so please contact us in the
                      first instance.
                    </li>
                  </ul>
                  <li>
                    {" "}
                    <u> Changes to this Privacy Policy</u>
                  </li>
                  <ul>
                    <li>
                      This Privacy Policy was last updated on 30
                      January 2024 and previous versions are archived
                      and can be provided on request.
                    </li>
                    <li>
                      It is important that the personal information we
                      hold about you is accurate and current. Please
                      keep us informed if your personal information
                      changes during your relationship with us.
                    </li>
                    <li>
                      This Privacy Policy is subject to change without
                      notice and is updated or amended from time to
                      time and will be effective once we upload the
                      amended version to the Platform. Your continued
                      access or use of our Services constitutes your
                      acceptance of this Privacy Policy, as amended.
                      It is your responsibility to read this document
                      periodically to ensure you are aware of any
                      changes.
                    </li>
                  </ul>
                  <li>
                    <u> Third-Party Links on Platform </u>
                    <div className="ml-4">
                      The Platform may include links to third-party
                      platforms, plug-ins, and applications. Clicking
                      on those links or enabling those connections may
                      allow third parties to collect or share
                      information about you. We do not control these
                      third-party platforms and are not responsible
                      for their privacy statements or terms. When you
                      leave our Platform, or engage with such third
                      parties, we encourage you to read the distinct
                      privacy policy of every third-party you engage
                      with.
                    </div>
                  </li>
                </ul>
              </ul>
              <ul>
                <li>
                  {" "}
                  <b> What We Collect About You</b>
                </li>
                <ul>
                  <li>
                    Personal information, or personally identifiable
                    information, means any information about an
                    individual, both natural and juristic entities
                    (i.e., people and companies), from which that
                    entity can be identified. It does not include
                    information where the identity has been removed
                    (anonymous data).
                  </li>
                  <li>
                    We may collect, use, store, and transfer (“
                    <b> process </b>”) different kinds of personal
                    information about you which we have grouped
                    together as follows:
                    <ul>
                      <li>
                        <p>
                          <b> Identity Data</b> including full name,
                          age, or the information about your company
                          such as company name, address and/or company
                          registration details;
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Contact Data</b> including email
                          address, physical/registered addresses, and
                          contact phone numbers;
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Minor Data </b> including full name,
                          identity number, email address,
                          physical/registered addresses, contact phone
                          numbers and all associated data as listed
                          below;{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Profile Data </b> including all the data
                          and information available in your Xylus
                          profile such as your employment history,
                          educational qualifications and payment
                          history;{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Social Media Data </b> including all the
                          data available on your publicly available
                          social media profile;{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Financial Data</b> including a salary
                          information, bank account details and
                          third-party payment provider information and
                          payment card details (which we do not
                          process but is processed by a payment
                          service provider on our instruction);
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Transaction Data</b> including details
                          about payments to and from you, contracts,
                          contractual terms, contract fees, signups,
                          subscriptions, invoices and other details of
                          products and services you have obtained from
                          us, or provide to us;
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Technical</b> Data including internet
                          protocol address/es, login data, browser
                          type and version, time zone setting and
                          location, cookies, browser plug-in types and
                          versions, operating system and platform and
                          other technology on the devices you use to
                          access the Platform;
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Usage Data</b> including information
                          about how you use our company, Platform,
                          surveys, and Services; and
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Marketing and Communications Data</b>
                          including your preferences in receiving
                          notices and marketing from us and our third
                          parties and your communication preferences
                          as well as details of which communications
                          were sent to you and how they were sent.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b> Support Data</b> including all messages
                          you send to us via email and through the
                          Platform.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ul>
              <p>
                We also collect, use, and share{" "}
                <b> Aggregated Data</b> such as statistical or
                demographic data for any purpose. Aggregated Data may
                be derived from your personal information but is not
                considered personal information in law as this data
                does <b> not</b> directly or indirectly reveal your
                identity. For example, we may aggregate your Usage
                Data to calculate the percentage of users accessing a
                specific Platform feature. However, if we combine or
                connect Aggregated Data with your personal information
                so that it can directly or indirectly identify you, we
                treat the combined data as personal information which
                will be used in accordance with this Privacy Policy.
              </p>
              <p>
                Where we need to collect personal information by law,
                or under the terms of a contract we have with you and
                you fail to provide that data when requested, we may
                not be able to perform in terms of the contract we
                have or are trying to enter into with you (for
                example, to provide you with services or allow you to
                provide us with your services). In this case, we may
                have to cancel Platform-access or Services you have
                with us, but we will notify you if this is the case at
                the time.
              </p>
              <ul>
                <li>
                  <b> How Is Your Personal Information Collected </b>{" "}
                </li>
                <ul>
                  <li>
                    We use different methods to collect personal
                    information from and about you, including through:
                    <ul>
                      <li>
                        <b> Direct interactions:</b> You may give us
                        your personal information by signing up to our
                        website or by corresponding with us through
                        the Platform, by phone, email or otherwise.
                        This includes personal information you provide
                        when you:
                      </li>
                      <ul>
                        <li>use our Services;</li>
                        <li>use our Platform;</li>
                        <li>contract with us;</li>
                        <li>
                          provide any services to us as a service
                          provider or independent contractor on
                          contract with us;
                        </li>
                        <li>
                          request information to be sent to you;
                        </li>
                        <li>give us some feedback.</li>
                      </ul>
                    </ul>
                    <ul>
                      <li>
                        <b> Automated technologies </b> or
                        interactions:As you interact with our
                        Platform, we may automatically collect
                        Technical Data and Usage Data about your
                        equipment, browsing actions and patterns. We
                        may collect this personal information by using
                        cookies, server logs and other similar
                        technologies. We may also receive Technical
                        Data about you if you visit other Platforms
                        employing our cookies.
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <b> Third parties: </b> We may receive
                        personal information about you from various
                        third parties such as:
                      </li>
                      <ul>
                        <li>analytics providers; </li>
                        <li>tertiary education institutions; </li>
                        <li>marketing platforms; and</li>
                        <li>search information providers. </li>
                      </ul>
                    </ul>
                  </li>
                </ul>
              </ul>
              <ul>
                <li>
                  <b> How We Use Your Personal Information </b>
                </li>
                <ul>
                  <li>
                    We will only use your personal information when
                    the law allows us to and for legitimate reasons,
                    which you hereby expressly understand and consent
                    to. Most commonly, we will use your personal
                    information in the following circumstances:
                    <ul>
                      <li>
                        where we have your <b> express consent </b> to
                        do so;
                      </li>
                      <li>
                        where we need to consult with you or{" "}
                        <b> perform on the Services contract</b> we
                        are about to enter into or have entered into
                        with you;
                      </li>
                      <li>
                        where it is necessary for our{" "}
                        <b> legitimate business interests </b> (or
                        those of a third party) and your interests and
                        fundamental rights do not override those
                        interests; and/or
                      </li>
                      <li>
                        where we need to comply with a{" "}
                        <b> legal or regulatory obligation.</b>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ul>
              <ul>
                <li>
                  <b>
                    {" "}
                    Purposes For Which We Will Use Your Personal
                    Information
                  </b>
                </li>
                <ul>
                  <li>
                    We have set out below, in a table format, a
                    description of
                    <ul>
                      <li>
                        the purpose for which we will process your
                        personal information,
                      </li>
                      <li>the type of data we process; and</li>
                      <li>the lawful basis of processing</li>
                    </ul>
                  </li>
                  <li>
                    Note that we may process your personal information
                    for more than one lawful ground depending on the
                    specific purpose for which we are using your
                    personal information.
                  </li>
                </ul>
                <div className="table-responsive">
                  <table className="privacy-table">
                    <tr>
                      <th>PURPOSE / ACTIVITY</th>
                      <th>TYPE OF DATA</th>
                      <th>LAWFUL BASIS AND BUSINESS INTEREST</th>
                    </tr>
                    <tr>
                      <td>
                        To engage with you after you have contacted us
                        via the Platform or otherwise
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <ul>
                            <li>Financial</li>
                          </ul>
                          <li>Technical</li>
                          <li>Usage</li>
                          <li>Marketing and Communications</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Express consent</li>
                          <li>Performance of a contract with you</li>
                          <li>
                            Necessary for our legitimate interests (keep
                            records updated, study how users use our
                            services, develop services, grow)
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To allow you to use our Services and to provide
                        you with our Services as contracted
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <li>Profile</li>
                          <li>Social Media</li>
                          <li>Financial</li>
                          <li>Usage</li>
                          <li>Transaction</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Performance of a contract with you</li>
                          <li>Express consent </li>
                          <li>
                            Necessary to comply with a legal obligation
                          </li>
                          <li>
                            Necessary for our legitimate interests
                            (provide you with Services you contracted to
                            acquire, keep records updated, study how
                            users use Services)
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To contract with you as a service provider to
                        Xylus
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Financial</li>
                          <li>Transaction</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Performance of a contract with you</li>
                          <li>Express consent </li>
                          <li>
                            Necessary to comply with a legal obligation
                          </li>
                          <li>
                            Necessary for our legitimate interests
                            (provide us with your services, keep our
                            records updated, and study how we may use
                            third-party services)
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To provide it to our authorised service
                        providers who need your personal information to
                        provide their services to you{" "}
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <li>Financial</li>
                          <li>Transaction</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Performance of a contract with you</li>
                          <li>
                            Necessary for our legitimate interests
                            (provide you with Services you contracted
                            from the third-party, develop our services,
                            and grow)
                          </li>
                          <li>Express consent </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To process and service your payment for any
                        services rendered by Xylus or its service
                        providers.
                        <p></p>
                        To manage payments, fees, and charges
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <li>Financial</li>
                          <li>Transaction</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Performance of a contract with you</li>
                          <li>
                            Necessary for our legitimate interests (make
                            or receive necessary payments)
                          </li>
                          <li>Express consent </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To manage our relationship with you which may
                        include notifying you about changes to our
                        Terms, Privacy Policy, or Services
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <li>Social Media</li>
                          <li>Marketing and Communications</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Performance of a contract with you</li>
                          <li>
                            Necessary to comply with a legal obligation
                          </li>
                          <li>
                            Necessary for our legitimate interests{" "}
                          </li>
                          <li>Express consent </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To administer and protect our company, Platform
                        and services (including troubleshooting, data
                        analysis, testing, system maintenance, support,
                        reporting and hosting of data)
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <li>Technical</li>
                          <li>Usage</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Necessary for our legitimate interests
                            (running our organisation, provision of
                            administration and IT, network security, to
                            prevent fraud and organisation restructuring
                            exercise)
                          </li>
                          <li>
                            Necessary to comply with a legal obligation
                          </li>
                          <li>Express consent </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To use data analytics to improve our Platform,
                        Services, and client relationships and
                        experiences
                      </td>
                      <td>
                        <ul>
                          <li>Technical</li>
                          <li>Usage</li>
                          <li>Marketing and Communications</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Necessary for our legitimate interests
                            (define types of users, keep Platform
                            updated, develop our organisation and inform
                            our marketing strategy)
                          </li>
                          <li>Express consent </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To provide you with direct marketing, make
                        suggestions about services that may be of
                        interest
                      </td>
                      <td>
                        <ul>
                          <li>Identity</li>
                          <li>Contact</li>
                          <li>Minor</li>
                          <li>Social Media</li>
                          <li>Technical</li>
                          <li>Usage</li>
                          <li>Marketing and Communications</li>
                          <li>Support</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Necessary for our legitimate interests
                            (develop our services and grow)
                          </li>
                          <li>Express consent </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </div>
                <ul>
                  <ul>
                    <li>
                      <u>

                        Marketing
                      </u>
                      <ul>
                        <li>

                          We strive to provide you with choices regarding certain personal information uses, particularly around marketing and advertising. To manifest your rights attached to any marketing sent to you as an existing user, please use the in-built prompts provided on those communications, or contact us.
                        </li>
                        <li>

                          You will receive marketing communications from us if you have requested our Services, requested information from us, or provided us with your details in any other circumstance and, in each case, have not opted-out of receiving that marketing.
                        </li>
                        <li>
                          You can ask us to stop sending you marketing messages at any time by using the built-in prompts or contacting us and requesting us to cease or change your marketing preferences. Where you opt-out of receiving these marketing messages, this opt-out will not apply to other personal information of yours which we process for another lawful basis or necessary communications we send when you use our Services.

                        </li>

                      </ul>
                    </li>
                    <li>
                      <u>

                        Third-Party Marketing
                      </u>
                      <div className="ms-4">

                        Whilst we may use your personal information within our company, we will get your express opt-in consent before we share your personal information publicly with any entity outside of Xylus for marketing.
                      </div>
                    </li>
                    <li>
                      <u>

                        Change of Purpose
                      </u>
                      <ul>
                        <li>

                          We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
                        </li>
                        <li>

                          If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
                        </li>
                        <li>

                          Please note that we may process your personal information without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ul>
                <ul>
                  <li>
                    <b>
                      Disclosures Of Your Personal Information

                    </b>
                    <ul>
                      <li>
                        We may have to share your personal information with the parties set out below for the purposes set out in the table above.

                        <ul>
                          <li>

                            Internal Third Parties as set out in the <b>
                              Glossary</b>;
                          </li>
                          <li>

                            External Third Parties as set out in the <b>
                              Glossary;
                            </b>
                          </li>
                          <li>

                            Third parties to whom we may choose to sell, transfer, or merge parts of our company or our assets. Alternatively, we may seek to acquire other organisations or merge with them. If a change happens to our company, we may continue to use your personal information in the same way as set out in this Privacy Policy.
                          </li>
                        </ul>
                      </li>
                      <li>

                        We require all third parties to respect the security of your personal information and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal information for their own purposes and only permit them to process your personal information in accordance with our instructions and standards.
                      </li>
                    </ul>
                  </li>
                  <li>

                    <b>
                      Cookies
                    </b>
                    <ul>
                      <li>

                        The Platform may make use of “cookies” to automatically collect information and data through the standard operation of the Internet servers. “Cookies” are small text files a platform can use (and which we may use) to recognise repeat users, facilitate the user’s on-going access to and use of a platform and allow a platform to track usage behaviour and compile aggregate data that will allow the platform operator to improve the functionality of the platform and its content, and to display more focused advertising to a user by way of third party tools.
                      </li>
                      <li>

                        The type of information collected by cookies is not used to personally identify you. If you do not want information collected using cookies, there is a simple procedure in most browsers that allows you to deny or accept the cookie feature. Please note that cookies may be necessary to provide you with certain features available on our Platform and thus if you disable the cookies on your browser you may not be able to use those features, and your access to our Platform will therefore be limited. If you do not disable “cookies”, you are deemed to consent to our use of any personal information collected using those cookies, subject to the provisions of this Privacy Policy and our other policies or terms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>

                      International Transfers
                    </b>
                    <ul>
                      <li>

                        We may share and process your personal information outside of South Africa for the purpose of cloud storage or to engage with third parties such as website developers.
                      </li>
                      <li>

                        Whenever we may transfer your personal information out of South Africa, we will ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
                        <ul>
                          <li>

                            We will only transfer your personal information to countries that have appropriate data protection legislation in place similar to that of South Africa; and/or
                          </li>
                          <li>
                            Where we use service providers, we may use specific contracts/clauses which ensure personal information is processed and secured lawfully.

                          </li>
                        </ul>
                      </li>
                      <li>
                        Please contact us if you want further information regarding transfer of personal information out of South Africa.

                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>

                      Data Security
                    </b>
                    <ul>
                      <li>

                        We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used, or accessed in an unauthorised way, altered, or disclosed by [insert a short sentence on your security measures]. We also limit access to your personal information to those employees, agents, contractors and other third parties who have a legitimate need to know. They will only process your personal information on our instruction and are subject to a duty of confidentiality.
                      </li>
                      <li>

                        We have put in place procedures to deal with any suspected personal information breach and will notify you and the Information Regulator of a breach where we are legally required to do so.
                      </li>

                    </ul>
                  </li>
                  <li>
                    <b>

                      Data Retention
                    </b>
                    <ul>
                      <li>

                        We will only retain your personal information for as long as necessary to fulfil the purpose we collected it for including any legal, accounting, or reporting requirements.
                      </li>
                      <li>

                        To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purpose for which we process your personal information, any other South African applicable law requiring us to retain the personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
                      </li>
                      <li>

                        We may also anonymise your personal information (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>

                      Your Legal Rights
                    </b>
                    <ul>
                      <li>

                        You have rights in relation to your personal information where we are the relevant “Responsible Party” over such personal information. Please contact us to find out more about, or manifest, these rights:
                        <ul>
                          <li>

                            request access to your personal information;
                          </li>
                          <li>

                            request correction of your personal information;
                          </li>
                          <li>
                            request erasure of your personal information;

                          </li>
                          <li>

                            object to the processing of your personal information;
                          </li>
                          <li>

                            request a restriction of processing your personal information;
                          </li>
                          <li>

                            request transfer of your personal information; and/or
                          </li>
                          <li>

                            right to withdraw consent.
                          </li>
                        </ul>
                      </li>
                      <li>

                        You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
                      </li>
                      <li>
                        We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal information (or to exercise any of your other rights). This is a security measure to ensure that personal information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.

                      </li>
                      <li>
                        We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.

                      </li>
                      <li>

                        Users with citizenships from jurisdictions other than South Africa, please note that we comply with South African data protection laws when processing your personal information as we are a South African entity. Should foreign law be applicable to your use of the Services and/or the Platform in any way, including how we may process your personal information, please contact us and we will gladly engage with you on your rights.
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <b>
                      Glossary
                    </b>
                    <ul>
                      <li>
                        <u>

                          Lawful Basis
                        </u>
                        <ul>
                          <li>

                            <b>
                              Legitimate Interest </b>means the interest of our organisation in conducting and managing our organisation to enable us to give you the best service and the most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
                          </li>
                          <li>

                            <b>
                              Performance of Contract</b> means processing your personal information where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
                          </li>
                          <li>

                            <b>Comply with a legal or regulatory obligation </b>means processing your personal information where it is necessary for compliance with a legal or regulatory obligation that we are subject to.
                          </li>
                          <li>

                            <b>Express consent </b>means the confirmed express consent you have provided to our processing of your personal information by actively accepting this Privacy Policy.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <u> Third Parties</u>
                        <ul>
                          <li>
                            <b>
                              Internal Third Parties </b>means other entities or parties in the Xylus group acting as joint responsible parties or operators.
                          </li>
                          <li>

                            <b>
                              External Third Parties </b>means:
                            <ul>
                              <li>

                                Authorised third-party service providers under contract with Xylus who need your personal information in order to contact and transact with you pursuant to your use of the Services;

                              </li>
                              <li>

                                service providers acting as operators;
                              </li>
                              <li>
                                South African or other national governments and/or their respective authorities pursuant to our adherence with anti-corruption and crime-fighting legislation; and/or

                              </li>
                              <li>

                                professional advisers acting as operators or joint responsible parties including lawyers, bankers, auditors and insurers based in South Africa who provide consultancy, banking, legal, insurance and accounting services as required.
                              </li>
                            </ul>
                          </li>
                        </ul>

                      </li>
                      <li>
                        <u>

                          Your Legal Rights
                        </u>

                        You have the right to:
                        <ul>
                          <li>
                            <b>
                              Request access </b>to your personal information (commonly known as a “<b>
                              data subject access request</b>”). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.

                          </li>
                          <li>
                            <b>
                              Request correction</b> of the personal information that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.

                          </li>
                          <li>


                            <b>
                              Request erasure</b> of your personal information. This enables you to ask us to delete or remove personal information where there is no valid reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal information to comply with local law. <b>
                              Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be communicated to you, if applicable, at the time of your request.
                            </b>
                          </li>
                          <li>

                            <b>
                              Object to processing</b> of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal information for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.

                          </li>
                          <li>
                            <b>
                              Request restriction of processing</b> of your personal information. This enables you to ask us to suspend the processing of your personal information in the following scenarios:
                            <ul>
                              <li>
                                if you want us to establish the data’s accuracy;

                              </li>
                              <li>
                                where our use of the data is unlawful but you do not want us to erase it;

                              </li>
                              <li>

                                where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or
                                you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                              </li>
                            </ul>
                          </li>
                          <li>

                            <b>
                              Request the transfer</b> of your personal information to you or to a third party. We will provide to you, or a third party you have chosen, your personal information in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform on a contract with you.
                          </li>
                          <li>

                            <b>
                              Withdraw consent at any time</b> where we are relying on consent to process your personal information. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain Platform access or Services to you. We will advise you if this is the case at the time you withdraw your consent. <b>
                              Please take note that regardless of your right to withdraw consent under POPI, other South African legislation applies and may require that we continue to process your data to comply with anti-corruption, crime-fighting and/or other national legislation, which you expressly understand and agree to.
                            </b>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ul>


              {/* <button className="readmore-btn">Read more</button> */}

            </div>
          </div>
        </div>
      </div>}
    </div >
  )
}

export default Signup
