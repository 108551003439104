import React, { useState, useEffect, useMemo } from "react";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader'
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { CSVLink } from "react-csv";
import GlobalFilter from "./GlobalFilter";
import environment from "../../environment";
import useLoader from '../Auth/Loader';
import InfiniteScroll from "react-infinite-scroll-component";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import $ from 'jquery'
toast.configure()
function Users() {
    const history = useHistory();
    var notifiy = false
    const [userType, setUserType] = useState(['Admin', 'College', 'Candidate', 'Company']);
    const [allData, setAllData] = useState([]);
    const [getData, setGetData] = useState([]);
    const [value, setValue] = useState('All');
    const [link, setLink] = useState("#");
    const [loader, showLoader, hideLoader] = useLoader();
    const [hasMore, setHasMore] = useState(true);
    const [filter, setFilter] = useState(false);
    const [model, setModel] = useState();
    const [allUsers, setAllUsers] = useState([]);
    const [role, setRole] = useState("all");
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 10,
        "role": role
    });
    const services = new service();

    useEffect(() => {
        GetAllData();
    }, []);
    useEffect(() => {

    }, [pageModel]);
    async function GetAllData(m) {
        showLoader()
        let pageM = m ? m : pageModel;
        var res = await services.GetAllUsers(ServiceAPI.getusersApi, pageM);
        hideLoader()
        const data = await res.result
        setModel(res.metaData.totalCount)
        setAllData([...allData, ...data]);
        if (m) {
            setGetData([...data]);
        }
        else {
            setGetData([...getData, ...data]);
            setPageModel({
                "pageNum": pageModel.pageNum + 1,
                "pageSize": 10,
                "role": role
            })
        }
        GetAllUsersData(res.metaData.totalCount)
    };

    async function GetAllUsersData(pageSize) {

        var res = await services.GetAllUsers(ServiceAPI.getusersApi, {
            "pageNum": 1,
            "pageSize": pageSize,
            "role": "all"
        });
        const data = await res.result
        setAllUsers(data);
    };
    async function GetExcel(e, user) {
        e.preventDefault();
        var res = await services.Create(ServiceAPI.UserExcelApi, {
            "roles": `${user}`
        })

        const url = res.result;
        window.location.href = url;
        // setLink(url)
    };
    const ChangeHandler = (e) => {
        setFilter(false)
        setGlobalFilter('')
        if (e.target.value === "Admin") {
            // let data = allData.filter(type => type.role === "Admin")
            // setGetData(data);
            setHasMore(false)
            setRole("admin")
            setPageModel({
                "pageNum": 2,
                "pageSize": 10,
                "role": "admin"
            })
            GetAllData({
                "pageNum": 1,
                "pageSize": 10,
                "role": "admin"
            });
            setValue(e.target.value)
        } else if (e.target.value === "College") {
            // let data = allData.filter(type => type.role === "College")
            // setGetData(data);
            setHasMore(true)
            setRole("college")
            setPageModel({
                "pageNum": 2,
                "pageSize": 10,
                "role": "college"
            })
            GetAllData({
                "pageNum": 1,
                "pageSize": 10,
                "role": "college"
            });
            setValue(e.target.value)
        } else if (e.target.value === "Candidate") {
            // let data = allData.filter(type => type.role === "Candidate")
            // setGetData(data);
            setHasMore(true)
            setRole("candidate")
            setPageModel({
                "pageNum": 2,
                "pageSize": 10,
                "role": "candidate"
            })
            GetAllData({
                "pageNum": 1,
                "pageSize": 10,
                "role": "candidate"
            });
            setValue(e.target.value)
        } else if (e.target.value === "Company") {
            // let data = allData.filter(type => type.role === "Company")
            // setGetData(data);
            setHasMore(true)
            setRole("company")
            setPageModel({
                "pageNum": 2,
                "pageSize": 10,
                "role": "company"
            })
            GetAllData({
                "pageNum": 1,
                "pageSize": 10,
                "role": "company"
            });
            setValue(e.target.value)
        } else {
            setHasMore(true)
            setRole("all")
            setPageModel({
                "pageNum": 2,
                "pageSize": 10,
                "role": "all"
            })
            GetAllData({
                "pageNum": 1,
                "pageSize": 10,
                "role": "all"
            });
            setValue(e.target.value)
        }

    }
    const headers = [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Email", key: "email" }
    ];

    const HandlChange = async (e, index, role) => {
        let data = (filter == true) ? allUsers : getData
        let newArr = data.map((item, i) => {
            if (index == i) {
                if (role == "Admin") {
                    toast.error("Can Not change Admin status", { theme: "colored", toastId: 'error2' });
                }
                return { ...item, isActive: e.target.checked };
            } else {
                return item;
            }
        });
        filter == true ? setAllUsers(newArr) : setGetData(newArr);
        var response = null;
        var val = { email: newArr[index].email }
        response = await services.Create(ServiceAPI.updateusersApi, val);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
            }, {
                Header: 'Last Name',
                accessor: 'lastName',
            }, {
                Header: "Status",
                accessor: "isActive",
                Cell: ({ row }) => {
                    const val = filter == true ? allUsers.map((item, index) => item.isActive) : getData.map((item, index) => item.isActive)
                    const role = filter == true ? allUsers.map((item, index) => item.role) : getData.map((item, index) => item.role);
                    return (
                        <label className="switch">
                            <input
                                id="isActive"
                                checked={role[row.index] === "Admin" ? true : val[row.index]}
                                type="checkbox"
                                onChange={(e) => HandlChange(e, row.index, role[row.index])}
                            />
                            <span className="slider round"></span>
                        </label>
                    )
                },
            }, {
                Header: 'Email',
                accessor: 'email',
            }, {
                Header: 'Role',
                accessor: 'role',
            }, {
                Header: "Edit",
                accessor: "edit",
                Cell: ({ row }) => {
                    const itemID = filter == true ? allUsers.map((item, index) => item.id) : getData.map((item, index) => item.id);
                    const role = filter == true ? allUsers.map((item, index) => item.role) : getData.map((item, index) => item.role);
                    return (
                        <label>
                            <a
                                onClick={() => editfunction(itemID[row.index], role[row.index])}
                                className="fa fa-pencil edit"
                                aria-hidden="true"
                            />
                        </label>
                    );
                },
            }, {
                Header: "Delete",
                accessor: "delete",
                Cell: ({ row }) => {
                    const itemID = filter == true ? allUsers.map((item, index) => item.id) : getData.map((item, index) => item.id);
                    const role = filter == true ? allUsers.map((item, index) => item.role) : getData.map((item, index) => item.role);
                    return (

                        <label>
                            <a
                                onClick={() => Delete(itemID[row.index], row.index, role[row.index])}
                                className="fa fa-trash delete"
                                aria-hidden="true"
                            />
                        </label>

                    );
                },
            }
        ], [filter == true ? allUsers : getData])

    const editfunction = (id, role) => {

        if (role == "Admin") {
            toast.error("Can Not Edit Admin", { theme: "colored", toastId: 'error1' });
        } else {
            history.push("/updateprofile/" + id);
        }
    };

    const Delete = (id, index, rol) => {
        if (rol == "Admin") {
            toast.error("Can Not Delete Admin", { theme: "colored", toastId: 'error2' });
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="xy-form">
                            <div className="mdl-heading">Delete Record</div>
                            <div className="desc">Are you sure to delete it?</div>
                            <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
                            <button className="btn btn-outline s-btn pr"
                                onClick={async () => {
                                    let filterdata = (filter == true) ? allUsers : getData
                                    let item = filterdata.filter((data) => data.id != id);
                                    await services.DeleteUser(ServiceAPI.AdminUserDeleteApi, id).then(async () => {
                                        var res = await services.GetAllUsers(ServiceAPI.getusersApi, {
                                            "pageNum": parseInt((item.length / 10) + 1),
                                            "pageSize": 10,
                                            "role": role
                                        });
                                        const model = await res.metaData;
                                        // setModel(model.totalCount);
                                        const data = await res.result;
                                        if (model.totalPages > parseInt((index / 10) + 1)) {
                                            if (filterdata.length === 10 * model.currentPage && data.length > 9) {
                                                filter == true ? setAllUsers([...item, data[data.length - 1]]) : setGetData([...item, data[data.length - 1]]);
                                            } else {
                                                filter == true ? setAllUsers(item) : setGetData(item);
                                            }
                                        } else {
                                            filter == true ? setAllUsers(item) : setGetData(item);
                                        }
                                    })
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    );
                }
            });
        }
    };
    const fetchMoreData = () => {
        setFilter(false)
        if (model > getData.length) {
            GetAllData()
        } else {
            setHasMore(false)
        }

    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        setGlobalFilter,
        prepareRow,
    } = useTable({
        columns: columns,
        data: filter == true ? allUsers : getData,
        autoResetGlobalFilter: false,
        // initialState: { pageSize: 10 }
    },

        useGlobalFilter,
        usePagination,

    );
    // setGetData(rows?rows:)
    const { globalFilter } = state
    // getData.forEach((data, index) => { data.serial = index + 1; });
    const getFilter = (e) => {
        setFilter(true)
        let value = e.target.value;
        if (getData.length != 0) {
            setGlobalFilter(value);
        }
    }
    const showNotification = (type) => {
        notifiy = $(".notification-menu").hasClass("show")
        if (notifiy == true) {
            $(".notification-menu").removeClass("show")
            notifiy = false
        }



    }
    return (
        <><AdminHeader heading="" subheading="Manage Users" />
            <form>
                <div className="theme-container-admin min-h h-100 master-admin">
                    <AdminNav users="active"></AdminNav>
                    <div className="display-inline pb-0" onClick={() => showNotification()}>
                        <div className="select-option">

                            <span className="label">Users</span>
                            <select className="xu-input " onChange={(e) => ChangeHandler(e)} >
                                <option key="All" >All</option>
                                {
                                    userType.map((item) => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ))}
                            </select>

                            {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} getData={rows} /> */}
                            <div className='search-bar'>
                                <div className='label'>
                                    Search : {' '}
                                </div>
                                <input value={globalFilter || ''}
                                    onChange={(e) => getFilter(e)} placeholder='Search Here' className='xu-input' />

                            </div>
                        </div>


                        <div className="display-inline csv-ex-btns">
                            <CSVLink data={allUsers} headers={headers}> <a className="csv btn btn-outline s-btn mr-2">CSV</a> </CSVLink>
                            <div><a onClick={(e) => GetExcel(e, value)} className="excel btn btn-outline s-btn">excel</a></div></div>
                    </div>
                    <div>
                        <div>
                            <div className="n-child ad-table users-tab" id="scrollableDiv"
                            >
                                <InfiniteScroll
                                    dataLength={allData.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={getData.length <= 10 ? '' : <div className="load-text">Loading ...</div>}
                                    scrollableTarget="scrollableDiv"
                                    // endMessage={data.length > 10 ?
                                    //     <p style={{ textAlign: 'center' }}>
                                    //         <b>You have seen it all</b>
                                    //     </p> : ''
                                    // }
                                    style={{ overflow: 'initial', }}

                                >
                                    <table {...getTableProps()} className="col-md-12 table">
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th
                                                            {...column.getHeaderProps()}
                                                            style={{

                                                            }}
                                                        >
                                                            {column.render('Header')}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {rows.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps()}
                                                                    style={{

                                                                    }}
                                                                >
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                            {/* <Table columns={columns} data={getData} update={fetchMoreData} /> */}
                            {/* <div className=" n-child ad-table scrollbar" id="scrollbar">
                               
                                {/* <div className="paginate">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{' '}
                                    </span> */}
                            {/* <span className="ml-3 mb-2 mt-3">
                                    Go to page: {' '}
                                    <input type='number' defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                        style={{ width: '50px' }} />
                                </span> */}
                            {/* <select className="ml-3 mb-2 mt-3 paginate-select xu-input" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                    {
                                        [5, 10, 15, 20].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                Show{pageSize}
                                            </option>
                                        ))
                                    }
                                </select> */}
                            {/* <button type="button" className="btn btn-outline ml-3 mb-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </button> */}
                            {/* <button type="button" className="btn btn-outline ml-3 mb-2" onClick={(e) => previousPage(e.preventDefault())} disabled={!canPreviousPage}>Previous</button>
                                    <button type="button" className="btn btn-outline ml-3 mb-2" onClick={(e) => nextPage(e.preventDefault())} disabled={!canNextPage}>Next</button> */}
                            {/* <button type="button" className="btn btn-outline ml-3 mb-2" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </button> */}
                            {/* </div> */}
                            {/* </div>  */}

                        </div>

                    </div>
                </div>
            </form>
            {loader}
        </>
    );
};

export default Users
