import React, { useState, useEffect, useRef } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Student/StudentModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import StudentNav from "./StudentNav";
import environment from '../../../environment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';
import useLoader from "../../Auth/Loader"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AdminHeader from '../../Common/AdminHeader'
import { FaRegTimesCircle } from "react-icons/fa";
import Avtar from '../../Auth/Avtar'
import $ from 'jquery'
import { useHistory } from "react-router-dom";
toast.configure()
const StudentProfile = (props) => {
    Common.ValidLogin();
    const history = useHistory();
    const id1 = props.id;
    const show = props.show == true ? true : ''
    const newDate = new Date();
    const formData = new FormData();
    const apiKey = "AIzaSyAVLTGiMQzxgYBYnviizjkO9g - HRLIEMH4";
    const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
    const searchInput = useRef('');
    const [languagelist, setLanguageList] = useState([])
    const [loader, showLoader, hideLoader] = useLoader();
    const [model, setModel] = useState(DefaultModel);
    const [imgurl, setImgurl] = useState();
    const [cimg, setCimg] = useState("");
    const [toolkit, setToolkit] = useState([]);
    const [skillset, setSkillSet] = useState([]);
    const [attribute, setAttribute] = useState([]);
    const [close, setClose] = useState(false)
    const [date, setDate] = useState(newDate);
    const [border, setBorder] = useState("xu-input");
    const [count, setCount] = useState(0)
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 100,
        "isActive": true
    });
    const [studentId, setStudentId] = useState('');
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [progress, setProgress] = useState(20);
    const [progress1, setProgress1] = useState(20);
    const [newtoolkit, setNewtoolkit] = useState([]);
    const [newskillset, setNewSkillSet] = useState([]);
    const [index, setIndex] = useState(0);
    const [index1, setIndex1] = useState(0);
    const [activetoolkit, setActivetoolkit] = useState(false);
    const [newtoolkitlist, setNewtoolkitlist] = useState([]);
    const [activeskillset, setActiveSkillSet] = useState(false);
    const [newskillsetlist, setNewSkillSetlist] = useState([]);
    const [showimage, setShowImage] = useState(false);
    const [name, setName] = useState('');
    const [name1, setName1] = useState('');
    const [showimage1, setShowImage1] = useState(false);
    const [filename, setFileName] = useState('')
    const services = new service();
    const [showavtar, setShowAvtar] = useState(true)
    const [collegeListOptions, setCollegeListOptions] = useState([])
    const [savedCollegeId, setSavedCollegeId] = useState()
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    useEffect(() => {
        if (id1 && props.show === true) {
            props.setShow(false)
            GetLanguage();
            GetById(id1);
            BindToolkit();
            BindAttribute();
            BindSkilSet();

        }
        else if (props.match || !props) {
            const id = props.location.search.split("=")[1];
            GetLanguage();
            if (id) { GetById(id); }
            BindToolkit();
            BindAttribute();
            BindSkilSet();
        }
        getCollageLanguage();

    }, [id1, show]);
    const GetLanguage = async () => {
        const resp = await services.GetAll(ServiceAPI.GetLanguageApi);
        const industryData = await resp.result.map((item) => {
            return {
                name: item.languageName,
                value: item.languageName,
                id: item.languageId
            }
        });
        industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        setLanguageList(industryData);
    }
    // init gmap script
    const extractAddress = (place) => {
        const address = {
            city: "",
            state: "",
            country: "",
            pincode: "",
            address: "",
            plain() {
                const city = city ? city + ", " : "";
                const pincode = pincode ? pincode + ", " : "";
                const state = state ? state + ", " : "";
                const address = address ? address + ", " : "";
                const country = country ? country + ", " : "";
                return city + pincode + state + country + address;
            }
        }

        if (!Array.isArray(place?.address_components)) {
            return address;
        }

        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;

            if (types.includes("locality")) {
                address.city = value;
            }

            if (types.includes("administrative_area_level_1")) {
                address.state = value;
            }

            if (types.includes("postal_code")) {
                address.pincode = value;
            }

            if (types.includes("country")) {
                address.country = value;
            }
            if (place) {
                address.address = place.formatted_address.split(",")[0];
            }
        });
        return address;
    }
    // do something on address change
    const onChangeAddress = async (autocomplete) => {
        const place = await autocomplete.getPlace();
        var values = extractAddress(place)
        setTimeout(() => {
            setModel({ ...JSON.parse(localStorage.getItem("stprofile")), ...values });
        }, 1000);
    }
    // init autocomplete
    const ChangeHandler = (e) => {
        // alert("ch1")
        let value = {};
        // if (e.target.id == "address") {
        //     initMapScript().then(() => initAutocomplete())
        // }
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        }
        else {
            value[e.target.id] = e.target.value;
        }

        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };

    const AddValue = async (e, field) => {
        if (field === "toolKits") {
            if (model.toolKits.map((item) => (item.toolKitId)).filter((val) => val == e.value) != e.value) {
                let value = { toolKits: [...model.toolKits, { studentId: studentId ? studentId : null, percentage: progress, toolKitId: e.value, name: e.label, toolkit: null, id: null, userId: Common.UserId(), active: true }] };
                setNewtoolkit(value.toolKits)
                setIndex(value.toolKits.length - 1)
            }
        }

        else if (field === "attributes") {
            let value = { attributes: [...model.attributes, { studentId: studentId ? studentId : null, attributeId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, attribute: null, id: null, userId: Common.UserId(), active: true }] };
            await setModel({ ...model, ...value });
        }
        else if (field === "studentLanguage") {
            let value = { studentLanguage: [...model.studentLanguage, { studentId: studentId ? studentId : null, languageId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, }] };
            await setModel({ ...model, ...value });
        }
        else if (field === "collegeId") {
            let value = { collegeId: e.value };
            await setModel({ ...model, ...value });
        }

    }
    const AddSkillSet = (e) => {

        if (model.skillSets.map((item) => (item.skillSetId)).filter((val) => val == e.value) != e.value) {
            let value = { skillSets: [...model.skillSets, { studentId: studentId ? studentId : null, percentage: progress1, skillSetId: e.value, name: e.label, id: null, userId: Common.UserId(), active: true }] };
            setNewSkillSet(value.skillSets)
            setIndex1(value.skillSets.length - 1)
        }
    }
    const RemoveValue = async (e, field) => {
        if (field === "toolKits") {
            let value = { toolKits: e }
            await setModel({ ...model, ...value });

        } else if (field === "attributes") {
            let value = { attributes: e }
            await setModel({ ...model, ...value });
        }
        else if (field === "studentLanguage") {
            let value = { studentLanguage: e }
            await setModel({ ...model, ...value });
        }
    }
    const updateDate = async (e, field) => {
        let value = {};
        value[field] = e;
        if (field === "dob") {
            setDate(e);
        }
        setModel({ ...model, ...value });
    }
    const UploadImage1 = async (e, img) => {
        $('.modal1').removeClass('modal2');
        setImgurl()
        setShowImage(true)
        var s = {
            bitcode: e.split(',')[0],
            userId: model.userId,
            role: "candidate",
            typeDir: "logo",
            extension: `${img.target.files[0].type.split("/")[1]}`
        }
        setCount(1)
        var data = await services.Create(ServiceAPI.EncodedImageUplaodApi, s);
        var url = environment.imageUrl + data.result;
        setImgurl(url);
        setShowImage(false)
        setFileName(img.target.files[0].name)
        let value = { profileImage: url };
        await setModel({ ...model, ...value });
    }
    const UploadCoverImage = async (e) => {
        setCimg()
        setShowImage1(true)
        formData.append('file', e.target.files[0]);
        formData.append('imageType', '1');
        var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "coverimg", formData);
        var url = environment.imageUrl + data.result;
        setCimg(url);
        setShowImage1(false)
        let img = { coverImage: url }
        await setModel({ ...model, ...img });
        setBorder({ ...border, [e.target.id]: "" })
    }
    const BindToolkit = async () => {
        var res = await services.PostGetAll(ServiceAPI.ToolKitGetAllApi, pageModel);
        const data = await res.result.filter((data) => data.active !== false);
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        await setToolkit(data);

    }
    const BindSkilSet = async () => {
        var res = await services.PostGetAll(ServiceAPI.SkillSetGetAllApi, pageModel);
        const data = await res.result.filter((data) => data.active !== false);
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        await setSkillSet(data);

    }
    const BindAttribute = async () => {
        var res = await services.PostGetAll(ServiceAPI.AttributeGetAllApi, pageModel);
        const data = await res.result.filter((data) => data.active !== false);
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        await setAttribute(data);
    }

    const Create = async (e) => {
        setClose(false)
        e.preventDefault();
        var response = null;

        if (valid()) {
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            showLoader();
            if (model.id === null) {
                response = await services.Create(ServiceAPI.StudentCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.StudentUpdateApi, model);
            }
            if (response && response.message === "Success") {
                localStorage.setItem("username", `${model.firstName} ${model.lastName}`);
                localStorage.setItem("profileImage", model.profileImage)
                if (props.id) {
                    props.GetstudentProfiles(Common.LoginId());
                    $(`#${props.modalid}`).click();
                    hideLoader();
                    if (count === 1) {
                        setTimeout(
                            function () {
                                hideLoader();
                                window.location = `/studentsprofile?id=${Common.LoginId()}`;
                            }, 2000);
                        $(`#${props.modalid}`).click();
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                hideLoader();
                                window.location = `/studentsprofile?id=${Common.LoginId()}`;
                            }, 2000);
                    }
                    else {
                        $(`#${props.modalid}`).click();
                        toast.success('Successful', { theme: "colored", autoClose: 2000 })
                        setTimeout(
                            function () {
                                hideLoader();
                                $('.modal-backdrop').removeClass('show ');
                                $('.modal').removeClass('show');
                                $('body').toggleClass('p-0');
                                $('body').removeClass('modal-open');
                                $('nav.navbar.navbar-expand-sm').addClass('p-0');
                                history.push(`/studentsprofile?id=${Common.LoginId()}`)
                            }, 2000);

                    }
                }
                else if (props.id2) {
                    props.GetstudentProfiles(Common.LoginId());
                    toast.success('Successful', { theme: "colored", autoClose: 2000 })
                }
                else {
                    hideLoader(Common.LoginId());
                    GetById()
                    if (count === 1) {
                        setTimeout(
                            function () {
                                hideLoader();
                                window.location = `/studentprofile?id=${Common.LoginId()}`;
                                setModel(DefaultModel);
                            }, 2000);
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                hideLoader();
                                window.location = `/studentprofile?id=${Common.LoginId()}`;
                                setModel(DefaultModel);
                            }, 2000);
                    }
                    else {
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                hideLoader();
                                $('.modal-backdrop').removeClass('show ');
                                $('.modal').removeClass('show');
                                $('body').toggleClass('p-0');
                                $('body').removeClass('modal-open');
                                $('nav.navbar.navbar-expand-sm').addClass('p-0');
                                history.push(`/studentprofile?id=${Common.LoginId()}`)
                            }, 2000);
                    }
                }
            } else {
                toast.error(response.errorMessage, { theme: "colored", autoClose: 2000 })
                hideLoader();
            }
        }
    };

    const GetById = async (id) => {
        if (!show) {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
        }

        showLoader()
        setClose(false)
        var res = await services.GetStudentDataById(ServiceAPI.StudentGetApi, Common.LoginId(), {
            "studentId": Common.LoginId(),
            "companyId": null,
            'collegeId': null
        });
        hideLoader()
        const data = await res.result;
        localStorage.setItem("username", `${data.firstName} ${data.lastName}`);
        localStorage.setItem("profileImage", data.profileImage)
        setStudentId(data.id)
        setSavedCollegeId(data?.collegeId)
        setSelectedLanguages(data?.studentLanguage.map(language => ({
            name: language.languageNavigation.languageName,
            value: language.languageNavigation.languageName,
            id: language.languageId,
        })))
        setModel(data);
        localStorage.setItem("stprofile", JSON.stringify(data))
        setImgurl(data.profileImage);
        setCimg(data.coverImage);
        setDate(new Date(data.dob));
        setNewtoolkit(data ? data.toolKits : '')
        setNewSkillSet(data ? data.skillSets : '')
        if (data.toolKits.length > 0) {
            setProgress(data.toolKits[data.toolKits.length - 1].percentage)
        }
        if (data.skillSets.length > 0) {
            setProgress1(data.skillSets[data.skillSets.length - 1].percentage)
        }
    };

    const valid = () => {
        let Error = [];
        let firstName = '';
        let lastName = '';
        let email = '';
        let profileImage = '';
        let coverImage = '';
        let gender = '';
        let dob = '';
        let mobileNo = '';
        let studentLanguage = '';

        const trimOrDefault = (value) => (value || '').trim();

        if (!Boolean(trimOrDefault(model.firstName))) {
            Error.push("FirstName is required.");
            firstName = "borderred";
        }
        if (!Boolean(trimOrDefault(model.lastName))) {
            Error.push("LastName is required.");
            lastName = "borderred";
        }
        if (!Boolean(trimOrDefault(model.email))) {
            Error.push("Email is required.");
            email = "borderred";
        }
        if (!Boolean(model.profileImage)) {
            Error.push("ProfileImage is required.");
            profileImage = "borderred";
        }
        if (!Boolean(trimOrDefault(model.coverImage))) {
            Error.push("Leading Image is required.");
            coverImage = "borderred";
        }
        // if (!Boolean(model.gender)) {
        //     Error.push("Gender is required.");
        //     gender = "borderred"
        // }
        if (!Boolean(trimOrDefault(model.dob))) {
            Error.push("Date of Birth is required.");
            dob = "borderred";
        }
        if (new Date(model.dob) > new Date()) {
            Error.push("Date of Birth Can't Be In Future");
            dob = "borderred";
        }
        if (!Boolean(trimOrDefault(model.mobileNo))) {
            Error.push("Mobile number is required.");
            mobileNo = "borderred";
        }
        if (!Boolean(model.toolKits.length)) {
            Error.push("ToolKits are required.");
        }
        if (!Boolean(model.studentLanguage.length)) {
            Error.push("Language is required.");
        }
        if (!Boolean(model.attributes.length)) {
            Error.push("Attributes are required.");
        }

        if (firstName || lastName || email || profileImage || coverImage || dob || gender || mobileNo || studentLanguage) {
            setBorder({ firstName, lastName, email, profileImage, coverImage, dob, gender, mobileNo, studentLanguage });
        }

        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored" });
            return false;
        } else {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }
    function CircularProgressWithLabel1(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }
    // percnatge progress bar values

    const marks = [

        {
            value: 25,
            label: '25%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 75,
            label: '75%',
        },
        {
            value: 99,
            label: '99%',
        },
    ];

    function valuetext(value) {

        setProgress(value)
        return `${value}%`;
    }
    function valuetext1(value) {
        setProgress1(value)
        return `${value}%`;
    }
    const handleClickOpenskillset = (type) => {

        setActiveSkillSet(false)
        setOpen1(true);
        const results = skillset.filter(({ name: id1 }) => !model.skillSets.some(({ name: id2 }) => id2 === id1));
        setNewSkillSetlist(results)
    };
    const handleClickOpen = (type) => {

        setActivetoolkit(false)
        setOpen(true);
        const results = toolkit.filter(({ name: id1 }) => !model.toolKits.some(({ name: id2 }) => id2 === id1));
        setNewtoolkitlist(results)

    };
    const handleClickUpdateskillset = (e, percentage, index, type) => {
        setProgress1(percentage)
        setName1(e)
        setIndex1(index)
        setActiveSkillSet(true)
        setOpen1(true);
    };
    const handleClickUpdate = (e, percentage, index, type) => {

        if (type == "skillset") {
            setProgress1(percentage)
            setName(e)
            setIndex1(index)
            setActiveSkillSet(true)

            setOpen(true);
        }
        else {
            setProgress(percentage)
            setName(e)
            setIndex(index)
            setActivetoolkit(true)
            setOpen(true);
        }

    };
    const handleRemove = (id, type) => {

        if (type == "skillset") {
            const filteredtoolkit = newskillset.filter((item) => item.skillSetId !== id);
            setNewSkillSet(filteredtoolkit);
            let value = { skillSets: filteredtoolkit ? filteredtoolkit : '' };
            setModel({ ...model, ...value });
        }
        else {
            const filteredtoolkit = newtoolkit.filter((item) => item.toolKitId !== id);
            setNewtoolkit(filteredtoolkit);
            let value = { toolKits: filteredtoolkit ? filteredtoolkit : '' };
            setModel({ ...model, ...value });
        }

    };
    const handleAdd = () => {
        if (newtoolkit.length > 0 && activetoolkit == true) {
            newtoolkit[index].percentage = progress
            setOpen(false);
        } if (newtoolkit.length > model.toolKits.length) {
            newtoolkit[index].percentage = progress
            setOpen(false);
        } if (newtoolkit.length == model.toolKits.length && activetoolkit == false) {
            toast.error('Please Select Valid Toolkit', { theme: "colored", autoClose: 4000 });
            setOpen(true);
        }
        let value = { toolKits: newtoolkit ? newtoolkit : '' };
        setModel({ ...model, ...value });
    };

    const handleAddSkillset = () => {

        if (newskillset.length > 0 && activeskillset == true) {
            newskillset[index1].percentage = progress1
            setOpen1(false);
        } if (newskillset.length > model.skillSets.length) {
            newskillset[index1].percentage = progress1
            setOpen1(false);
        } if (newskillset.length == model.skillSets.length && activeskillset == false) {
            toast.error('Please Select Valid skillset', { theme: "colored", autoClose: 2000 });
            setOpen1(true);
        }
        let value = { skillSets: newskillset ? newskillset : '' };
        setModel({ ...model, ...value });
    };
    const handleCloseskillset = () => {
        setNewSkillSet(model.skillSets)
        setOpen1(false);
    };
    const handleClose = () => {
        setNewtoolkit(model.toolKits)
        setOpen(false);
    };

    const closeModal = (type) => {
        setShowAvtar(false)
        setTimeout(() => {
            setShowAvtar(true)
            $(".modal1").removeClass("modal2")
        }, 10);

    }
    const customModal = () => {
        $("#exampleMediaAdd").addClass("modal2")
    }
    const getCollageLanguage = async () => {
        const res = await services.Create(ServiceAPI.CollegeGetAllApi, {
            "pageNum": 1,
            "pageSize": 100,
            "isActive": true,
        });
        const Options = res?.result?.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        setCollegeListOptions(Options)
    }
    return (
        <>
            {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Profile" />}
            <form onSubmit={Create} >
                <div className="theme-container-admin container-fluid">
                    {props.id || props.id2 ? "" : <StudentNav profile="active"></StudentNav>}
                    <div className="row">
                        <div className="col-md-6 mx-auto detail-box profile-box form-sec pr-1">
                            <div className="row m-0 align-items-center">
                                <div className="col-sm-2 text-center">
                                    {imgurl ? <img src={imgurl} className="profile-login sm" />
                                        : showimage ? <span className="img">
                                            <div className="admin-img-preview ">
                                                <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/user.svg`} className="ad-img img-fluid" />}
                                </div>
                                <div className="col-sm-10">
                                    <div className="label">
                                        Choose profile image<span className="required">*</span>
                                    </div>
                                    <div

                                        className="xu-input xpading"
                                    >
                                        <div className="btn btn-outline choose-file" id="logo" onClick={() => customModal()}>Choose file</div>
                                        <span className="file-name">  {filename ? filename : "No file chosen"} </span>
                                    </div>
                                </div>

                                <div className="col-sm-2">
                                    {cimg ? <img src={cimg} className="ad-img img-fluid" />
                                        : showimage1 ? <span className="img mt-2">
                                            <div className="admin-img-preview mt-2">
                                                <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                                </div>
                                <div className="col-sm-10">
                                    <div className="label">
                                        Leading Image<span className="required">*</span></div>
                                    <input
                                        type="file"
                                        id="coverImage"
                                        className={border.coverImage ? `${border.coverImage} xpading` : "xu-input xpading"}
                                        placeholder="Image"
                                        accept="image/*"
                                        onChange={(e) => UploadCoverImage(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">First Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="firstName"
                                        className={border.firstName ? border.firstName : "xu-input"}
                                        placeholder="First Name"
                                        value={model.firstName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Last Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="lastName"
                                        className={border.lastName ? border.lastName : "xu-input"}
                                        placeholder="Last Name"
                                        value={model.lastName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">College Name<span className="required">*</span></div>
                                    <Select
                                        options={collegeListOptions}
                                        placeholder={"select"}
                                        isSearchable="true"
                                        className="border-0 css-14jk2my-container p-0 xu-input select-clg"
                                        id="collegeId"
                                        displayValue="name"
                                        isDisabled={!!savedCollegeId}
                                        value={collegeListOptions.find(option => option.value === model?.collegeId)}
                                        onChange={(e) => AddValue(e, 'collegeId')} />
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Date of Birth<span className="required">*</span></div>
                                    <DatePicker
                                        autoComplete="none"
                                        id="dob"
                                        className={border.dob ? border.dob : "xu-input"}
                                        selected={date}
                                        onChange={(e) => updateDate(e, "dob")}
                                        dateFormat="dd-MM-yyyy"

                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="label"> Languages<span className="required">*</span></div>
                                    <Multiselect
                                        className="xu-input"
                                        id="studentLanguage"
                                        onSelect={(e) => AddValue(e, "studentLanguage")}
                                        selectedValues={selectedLanguages}
                                        options={languagelist}
                                        displayValue="name"
                                        onRemove={(e) => RemoveValue(e, "studentLanguage")}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Email<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="email"
                                        id="email"
                                        className={border.email ? border.email : "xu-input"}
                                        placeholder="email"
                                        value={model.email}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                            </div>{" "}
                        </div>{" "}
                        <div className="col-md-6 mx-auto detail-box profile-box form-sec pl-1">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="label">mobile number<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="number"
                                        id="mobileNo"
                                        pattern="/[0-9]{10}/"
                                        maxLength={15}
                                        className={border.mobileNo ? border.mobileNo : "xu-input"}
                                        placeholder="mobileNo"
                                        value={model.mobileNo}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">Introduction{`(${model.description ? model.description.length : 0}/500)`}</div>
                                    <textarea
                                        autoComplete="none"
                                        maxLength={500}
                                        type="text"
                                        rows="6"
                                        id="description"
                                        className="xu-textarea"
                                        placeholder="Description"
                                        value={model.description}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></textarea>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Instagram Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="instagramUrl"
                                        className="xu-input"
                                        placeholder="Url"
                                        value={model.instagramUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Facebook Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="facebookUrl"
                                        className="xu-input"
                                        placeholder="Url"
                                        value={model.facebookUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Twitter Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="twitterUrl"
                                        className="xu-input"
                                        placeholder="Url"
                                        value={model.twitterUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mx-auto detail-box profile-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-6">
                                    <div className="label">Postal code</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="pincode"
                                        className="xu-input"
                                        placeholder="PinCode"
                                        value={model.pincode}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">City</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="city"
                                        className="xu-input"
                                        placeholder="city"
                                        value={model.city}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">State/Province</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="state"
                                        className="xu-input"
                                        placeholder="State/Province"
                                        value={model.state}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Country</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="country"
                                        className="xu-input"
                                        placeholder="Country"
                                        value={model.country}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-12">
                                    <div className="display-inline ">
                                        <div className="label">
                                            skillset
                                        </div>
                                        <div>
                                            <Button variant="contained" className="btn btn-outline tk-btn " onClick={handleClickOpenskillset}>
                                                Add
                                            </Button>
                                            <Dialog
                                                open={open1}
                                                onClose={handleCloseskillset}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                {activeskillset == true ? <DialogTitle id="alert-dialog-title">{"Update Your SkillSet"}</DialogTitle> : <DialogTitle id="alert-dialog-title">{"Add Your SkillSet"}</DialogTitle>}
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        <div className="col-md-12 p-0">
                                                            {activeskillset == true ? < div>{name1.charAt(0).toUpperCase() + name1.slice(1)}</div> : <><div className="label mb-2">skillset<span className="required">*</span></div>
                                                                <Select
                                                                    id='skillSets'
                                                                    onChange={(e) => AddSkillSet(e)}
                                                                    options={newskillsetlist ? newskillsetlist.map((data1) => ({ label: data1.name, value: data1.id })) : []}
                                                                />
                                                            </>
                                                            }
                                                        </div>
                                                        <Box className="mt-4 slider-w">
                                                            <Slider
                                                                aria-label="Custom marks"
                                                                defaultValue={activeskillset === true ? progress1 : 0}
                                                                getAriaValueText={valuetext1}
                                                                step={25}
                                                                valueLabelDisplay="auto"
                                                                marks={marks}
                                                                min={25}
                                                                max={99}
                                                            />
                                                        </Box>
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions className="tk-row">
                                                    <Button onClick={handleCloseskillset} className="btn btn-outline tk-btn2">Cancel</Button>
                                                    {activeskillset == true ? <Button onClick={handleAddSkillset} className="btn btn-outline tk-btn2">
                                                        Update
                                                    </Button> : <Button onClick={handleAddSkillset} className="btn btn-outline tk-btn2">
                                                        Add
                                                    </Button>}
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                        <ul width={100} className="list-group list-group-horizontal" >
                                            {model.skillSets ? model.skillSets.map((item, index) => <div key={index} className="tool" >
                                                <a onClick={(e) => handleClickUpdateskillset(item.name, item.percentage, index, "skillset")}  >
                                                    <li ><CircularProgressWithLabel1 style={{ color: 'orangered' }} value={item.percentage} /></li>
                                                    <li >{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</li>
                                                </a>
                                                <a className="add-option" onClick={(e) => handleRemove(item.skillSetId, "skillset")}><FaRegTimesCircle style={{ color: '#FF5A00', height: "20px", width: "20px" }} /></a>
                                            </div>
                                            ) : ''}

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-6 mx-auto detail-box profile-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="display-inline ">
                                        <div className="label">
                                            Toolkit<span className="required">*</span>
                                        </div>
                                        <div>
                                            <Button variant="contained" className="btn btn-outline tk-btn " onClick={handleClickOpen}>
                                                Add
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                {activetoolkit == true ? <DialogTitle id="alert-dialog-title">{"Update Your Toolkit"}</DialogTitle> : <DialogTitle id="alert-dialog-title">{"Add Your Toolkit"}</DialogTitle>}
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        <div className="col-md-12 p-0">
                                                            {activetoolkit == true ? < div>{name.charAt(0).toUpperCase() + name.slice(1)}</div> : <><div className="label mb-2">Toolkit<span className="required">*</span></div>
                                                                <Select
                                                                    id='toolKits'
                                                                    // isMulti
                                                                    onChange={(e) => AddValue(e, 'toolKits')}
                                                                    options={newtoolkitlist ? newtoolkitlist.map((data1) => ({ label: data1.name, value: data1.id })) : []}
                                                                />
                                                            </>
                                                            }
                                                        </div>
                                                        <Box className="mt-4 slider-w">
                                                            <Slider
                                                                aria-label="Custom marks"
                                                                defaultValue={activetoolkit === true ? progress : 0}
                                                                getAriaValueText={valuetext}
                                                                step={25}
                                                                valueLabelDisplay="auto"
                                                                marks={marks}
                                                                min={25}
                                                                max={99}
                                                            />
                                                        </Box>
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions className="tk-row">
                                                    <Button onClick={handleClose} className="btn btn-outline tk-btn2">Cancel</Button>
                                                    {activetoolkit == true ? <Button onClick={handleAdd} className="btn btn-outline tk-btn2">
                                                        Update
                                                    </Button> : <Button onClick={handleAdd} className="btn btn-outline tk-btn2">
                                                        Add
                                                    </Button>}
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                        <ul width={100} className="list-group list-group-horizontal" >
                                            {model.toolKits ? model.toolKits.map((item, index) => <div key={index} className="tool" >
                                                <a onClick={(e) => handleClickUpdate(item.name, item.percentage, index)}  >
                                                    <li ><CircularProgressWithLabel style={{ color: 'orangered' }} value={item.percentage} /></li>
                                                    <li >{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</li>
                                                </a>
                                                <a className="add-option" onClick={(e) => handleRemove(item.toolKitId)}><FaRegTimesCircle style={{ color: '#FF5A00', height: "20px", width: "20px" }} /></a>
                                            </div>
                                            ) : ''}

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">Attributes<span className="required">*</span></div>
                                    <Multiselect
                                        className="xu-input"
                                        id="attributes"
                                        onSelect={(e) => AddValue(e, "attributes")}
                                        selectedValues={model.attributes}
                                        options={attribute}
                                        displayValue="name"
                                        onRemove={(e) => RemoveValue(e, "attributes")}
                                    />
                                </div>
                                <div className="col-md-12 spacing">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right"
                                        data-dismiss={close ? "modal" : ""}
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal bg-modal modal1"
                    id={`exampleMediaAdd`}
                    aria-labelledby="exampleMediaAdd"
                >
                    <div className="modal-dialog modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header flex">
                                {/* <div className="modal-header "> */}
                                <div className="preview-title">PROFILE IMAGE </div>
                                <button type="button" data-dismiss-modal="modal2" className="close modalclose-btn" onClick={() => closeModal()}>&times;</button>
                                {/* </div> */}
                            </div>
                            <div className="modal-body">
                                {showavtar ? <Avtar UploadImage={UploadImage1} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    );


}
export default StudentProfile;