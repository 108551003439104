import React, { useEffect, useState } from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../../style.css";

function Texteditor(props) {
  const [state, setState] = useState({ value: '' });
  useEffect(() => {
    var values = props.data

    setState({ value: values })
  }, [props.data])
  const handleChange = (value) => {
    if (value.length <= 500 && state.value.length <= 500) {
      console.log("props", state.value, "val", value)
      setState({ value });
      props.ChangeHandler(value === "<p><br></p>" ? "" : value, "des")
    }


  };

  return (
    <div className="text-editor job-editor" >
      <ReactQuill
        theme="snow"
        value={state.value}
        onChange={state.value.length <= 500 ? (e) => handleChange(e) : ""}
        placeholder={"description"}
      />
    </div>
  )
}

export default Texteditor