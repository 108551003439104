import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Company/CompanyBannerModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import CompanyNav from "./CompanyNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";

toast.configure()

const CompanyBanner = (props) => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  Common.ValidLogin();
  const formData = new FormData();
  const id1 = props.id;
  const [model, setModel] = useState(DefaultModel);
  const [showimage, setShowImage] = useState(false);
  const [imgurl, setImgurl] = useState('');
  const [loader, showLoader, hideLoader] = useLoader();
  const [border, setBorder] = useState("xu-input");

  const services = new service();

  useEffect(() => {
    if (id1) {
      GetById(id1);
    }
    else if (props.match || !props) {
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, []);
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const UploadImage = async (e) => {
    setImgurl()
    setShowImage(true)
    formData.append('file', e.target.files[0]);
    formData.append('imageType', '1');
    var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "Banner", formData);
    var url = environment.imageUrl + data.result;
    setImgurl(url);
    setShowImage(false)
    let value = { imageUrl: url };
    await setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CompanyBannerCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CompanyBannerUpdateApi, model);
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          props.GetCompanyBanner();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          props.GetCompanyBanner();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          setImgurl('');
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            history.push("/companybannerlist");
            setModel(DefaultModel);
          }, 1000);

        }

      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CompanyBannerGetApi, id);
    hideLoader()
    const banner = await res.result;
    setModel(banner);
    setImgurl(banner.imageUrl);
  };

  const valid = () => {
    let Error = [];
    let name = "";
    let title = "";
    let imageUrl = "";
    let titleLink = "";
    function isValidWebUrl(url) {
      let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
      return regEx.test(url);
    }
    if (!Boolean(model.title.trim())) {
      Error.push("Valid Title required");
      title = "borderred"
    }
    if (!Boolean(model.imageUrl.trim())) {
      Error.push("Valid Image required");
      imageUrl = "borderred"
    }
    if (name || title || imageUrl || titleLink) {
      setBorder({ name, title, imageUrl, titleLink });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/companybannerlist");
  }
  const DeleteBanner = async (id) => {
    showLoader()
    const res = await services.Delete(ServiceAPI.CompanyBannerDeleteApi, id)
    hideLoader()
    if (res.message == "Success") {
      if (props.id) {
        props.GetCompanyBanner("delete");
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');

        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Banner" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <CompanyNav banner="active"></CompanyNav>}
          <div className="row">
            <div className=" mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-12">
                  <div className="label xy-label-float">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model.title}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label xy-label-float">Title Link<span className="required"></span></div>
                  <input
                    type="text"
                    id="titleLink"
                    className={border.titleLink ? border.titleLink : "xu-input"}
                    placeholder="Link"
                    value={model.titleLink}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="row m-0">
                    <div className="col-md-10 pl-0">
                      <div className="label xy-label-float">Choose Image<span className="required">*</span></div>
                      <input
                        type="file"
                        id="imageUrl"
                        className={border.imageUrl ? `${border.imageUrl} xpading` : "xu-input xpading"}
                        placeholder="Image"
                        accept="image/*"
                        onChange={(e) => UploadImage(e)}
                      ></input>
                    </div>
                    <div className="col-md-2 pr-0">
                      {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                        : showimage ? <span className="img mt-3">
                          <div className="admin-img-preview mt-3">
                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          </div>
                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                    </div>

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteBanner(model.id)}
                  >

                  </a>
                </div> : ""}
                <div className="col-md-12 spacing">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
};

export default CompanyBanner;
